<template>
  <div class style="position: relative;">
    <div id="searchcomponent"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AutocompleteResult } from './../models/AutocompleteResult';
import './../../libs/jo-informatik.searchcomponent.min.js';

declare const searchComponent;
declare const dafUser: string;
declare const dafPassword: string;

import './../../libs/jo-informatik.searchcomponent.css';
import { $$events } from '@/helpers/events';

let autocomplete: any = null;

@Component
export default class SearchComponent extends Vue {
  private setBlur() {
    autocomplete.blur();
  }

  private setActiveSource(sourceName: string) {
    autocomplete.setActiveSource(sourceName);
    setTimeout(() => {
      autocomplete.focus();
    }, 250);
  }

  private setSearchSourceAndId(payload: {
    sourceName: string;
    id: string;
    searchtitle: string;
    bfeNumbers: number[];
  }) {
    if (payload.sourceName === 'bfenumbers') {
      const vm = this;
      autocomplete
        .getSearchResultsFromBfeNumbers(payload.bfeNumbers)
        .then((data) => {
          if (data.length === 1) {
            this.$store.dispatch('search', data[0]);
          } else {
            this.$store.dispatch('search', null);
            vm.$store.commit('searchComponentResult', {
              title: payload.id,
              searchtitle: payload.searchtitle,
              data,
              supportsShowAll: true,
            });
          }
        });
    } else {
      autocomplete.setResultById(payload.sourceName, payload.id);
    }
  }

  private beforeDestroy() {
    $$events.$off('set-search-blur', this.setBlur);
    $$events.$off('set-search-id', this.setSearchSourceAndId);
    autocomplete.destroy();
  }
  private mounted() {
    const vm = this;
    $$events.$on('setActiveSource', this.setActiveSource);
    $$events.$on('set-search-blur', this.setBlur);
    $$events.$on('set-search-id', this.setSearchSourceAndId);

    autocomplete = searchComponent.createInstance({
      elementOrSelector: '#searchcomponent',
      sources: [
        {
          name: 'dawa.adresse',
          options: {
            hideWhenEmpty: true,
            fetchExtendedResult: false,
            minimumInputLength: 1,
            validInput: /^(\d{0,4}(\D.*|))$/,
            resultLimit: 1000,
          },
          filters: {
            level: 'adgangsadresse',
            dataResultType: 'ejendom',
            concatRelatedBFE: true,
          },
        },
        {
          name: 'dawa.jordstykke',
          options: {
            hideWhenEmpty: true,
            fetchExtendedResult: false,
            minimumInputLength: 1,
          },
          filters: {
            dataResultType: 'ejendom',
          },
        },
        {
          name: 'daf.ejendom',
        },
        // {
        //   name: 'daf.ejendomsnummer',
        //   options: {
        //     fetchExtendedResult: true,
        //   },
        //     filters: {
        //   dataResultType: "ejendom",
        // }
        //},
        //     filters: {
        //   dataResultType: "ejendom",
        // }
        //},
      ],
      layout: {
        searchButtonPlacement: 'right',
        width: '500px',
      },
      datafordeleren: {
        dafUser,
        dafPassword,
      },
      behavior: {
        showResultsIfSingleGroup: true,
        enterSelectsFirst: true,
      },
      localization: {
        placeholder: 'Søg på adresse, BFE-nr. eller matrikel',
      },
      events: {
        itemClicked: (item: AutocompleteResult) => {
          autocomplete.blur();

          vm.$store.commit('searchComponentResult', null);
          if (!Array.isArray(item.data)) {
            this.$store.dispatch('search', item);
          } else {
            if (item.data.length === 1) {
              this.$store.dispatch('search', item.data[0]);
            } else {
              if (item.datatype != 'ejendomsrelation') {
                this.$store.dispatch('search', null);
              }
              vm.$store.commit('searchComponentResult', item);
              if (item.datatype == 'ejendomsrelation') {
                this.$store.dispatch('search', item);
              }

              // if(item.source != 'daf.ejendomsnummer'){
              //   this.$store.dispatch('search', null);
              // }
              // vm.$store.commit('searchComponentResult', item);
              // if(item.source == 'daf.ejendomsnummer'){
              //   this.$store.dispatch('search', item);
              // }
            }
          }
        },
        cleared: (value: boolean) => {
          vm.$store.commit('searchComponentResult', null);
          this.$store.dispatch('search', null);
        },
      },
    });
  }
}
</script>
