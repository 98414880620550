<template>
  <div class="printoverlay py-5" @click.self="showPrint = false">

    <button title="Luk PDF Print" class="printoverlay__close btn btn-success rounded-circle" @click="showPrint = false">
      <svg fill="white" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20" height="20" viewBox="0 0 30.3 35" style="enable-background:new 0 0 30.3 35;" xml:space="preserve"> <g id="Symbols_1_"> <g id="Question-accordian-blue" transform="translate(0.000000, -1.000000)"> <g id="Group-10"> <g id="icon_x2F_Plus-icon-blue" transform="translate(0.000000, 1.000000)"> <g id="Plus-icon"> <g id="Stroke-3_1_"> <g> <path d="M29.2,32c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7L14.8,20.9c-0.2,0.3-0.3,0.3-0.3,0.3L4.2,31.6 c-0.8,0.8-2.4,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2l10.3-10.2c0.1-0.1,0.2-0.3,0.4-0.5L3.2,9.2c-0.4-0.4-0.7-1-0.7-1.6 S2.8,6.4,3.2,6c0.4-0.4,1-0.7,1.6-0.7C5.4,5.3,6,5.6,6.5,6l8.2,8.2c3.6-4.1,8.2-8.8,11.4-10.8c1.1-0.6,2.5-0.3,3.1,0.7 c0.3,0.5,0.4,1.2,0.3,1.7c-0.1,0.6-0.5,1.1-1,1.4c-2.2,1.3-5.9,4.9-10.5,10.1l11.4,11.4C30.1,29.7,30.1,31.1,29.2,32z"></path> </g> </g> </g> </g> </g> </g> </g> </svg>
    </button>

    <div class="printfunction text-right px-4 py-3">
      <!-- <button class="btn btn-link text-white" @click="showPrint = false">Luk vindue</button> -->
      <button class="btn btn-success" @click="print">Udskriv & Gem</button>
    </div>
    <div id="section-to-print" @click.self="showPrint = false">
    <!-- Pintede sider -->
    <div class="printpages" tabindex="0">
      <h1><strong>{{ title }}</strong></h1>
      <h5>{{ subtitle }}</h5>
      <div class="mt-4" style="width: 273mm; height: 155mm; position: relative;">
        <Map mapTarget="printmap" :interactive="false" :startPosition="mapPosition" />
      </div>
      <div class="pagefooter row">
        <div class="col mr-auto">{{footer}}</div>
        <div class="col text-right">1/{{totalPages}}</div>
      </div>
    </div>

    <div class="printpages pagebreak" tabindex="1">
      <h1><strong>{{ title }}</strong></h1>
      <h5>{{ subtitle }}</h5>
      <h6 class="font-weight-bold mt-3 mx-0 mb-2">Oversigt over alle anvendelseskoder for bygninger i BBR</h6>
      <div class="container-fluid px-0">
        <div class="c-print-pages-anvendelseskoder row">
          <div class="item col-3" v-for="(text, key) in bygningsanvendelser" :key="key">
                <div class="row" v-if="text != 'break'">
                <div class="col-auto pr-0 font-weight-bold">{{key}}</div>
                <div class="col pl-2">{{text}}</div>
              </div>
              <div class="row" v-else>
                <br>
              </div>
          </div>
        </div>
      </div>
      <div class="pagefooter row">
        <div class="col mr-auto">{{footer}}</div>
        <div class="col text-right">2/{{totalPages}}</div>
      </div>
    </div>
    
    <div class="printpages pagebreak" v-for="index in Math.ceil(items.length/6)" :key="index" :tabindex="index + 1">
      <h1><strong>{{ title }}</strong></h1>
      <h5>{{ subtitle }}</h5>
      <div class="pagetype">Eksisterende oplysninger i BBR</div>
      <div class="container-fluid px-0">
      <div class="card-container row mx-0">
        <div class="building-card card col-4 mx-0 px-0" v-for="(i, index) in items.slice((index-1)*6, (index-1)*6 + 6)" :key="i.id">
          <template v-if="!i.data">
            <form class="py-2 px-3">
              <div class="form-row">
                <div class="form-group col-6">
                  <label for="inputDate">Dato:</label>
                  <div class="d-flex">
                    <input type="number" id="inputDate" class="form-control" max="2" aria-label="Indtast dagsdato">
                    <span>-</span>
                    <input type="number" class="form-control" max="2" aria-label="Indtast måned">
                    <span>-</span>
                    <input type="number" class="form-control" max="4" style="width: 20mm;" aria-label="Indtast år">
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label for="inputOwner">Jeg er (sæt kryds):</label>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="radioType" id="radioOwner" value="option1">
                    <label class="custom-control-label" for="radioOwner">
                      Ejer/administrator
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="radioType" id="radioInspector" value="option2">
                    <label class="custom-control-label" for="radioInspector">
                      Landinspektør
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="radioType" id="radioConsultent" value="option2">
                    <label class="custom-control-label" for="radioConsultent">
                      Konsulent
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="radioType" id="radioOther" value="option2">
                    <label class="custom-control-label" for="radioOther">
                      Andet
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label for="inputName">Navn (Blokbogstaver):</label>
                  <input type="text" class="form-control text-uppercase" id="inputName">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label for="inputSign">Underskrift:</label>
                  <input type="text" class="form-control" id="inputSign" disabled>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label for="inputPhone">Tlf.:</label>
                  <input type="text" class="form-control" id="inputPhone">
                </div>
                <div class="form-group col-12">
                  <label for="inputEmail">E-mail:</label>
                  <input type="email" class="form-control" id="inputEmail">
                </div>
              </div>
            </form>
          </template>
          <template v-else>
          <div class="card-header px-3">
            <h5 class="card-title mb-0" v-if="i.type === ItemType.bygning">{{ i.title }}</h5>
            <h5 class="card-title mb-0" v-else>{{ i.title }}</h5>
            <div class="building-media">
              <span class="icon d-flex align-items-center text-center">
                <!-- <i
                  class="icon-BBR"
                  :style="{ color: i.iconColor }"> -->
                <img :src="i.itemImage.src" :alt="i.itemImage.alt" :title="i.itemImage.title" style=" height: 35px; width: 35px;" class="icon-BBR"/>
              <!-- </i> -->
              </span>
              <span class="text">{{ i.shortname }}</span>
              <!--<p>Ny anv. kode: ______</p> -->
            </div>
            <div class="anvendelseskode mt-2" v-if="i.type === ItemType.bygning">
              <div class="anvendelseskode__current">
                <span class="d-block" style="margin-bottom: 2px;">Anvendelseskode:</span>
                <p class="d-inline">{{ i.data.byg021BygningensAnvendelse }}</p> <span v-if="isDeprecated(i.data.byg021BygningensAnvendelse)" class="d-inline" style="color: rgb(219, 1, 1);">(Udfases)</span>
              </div>
              <div class="anvendelseskode__new">
                <span class="d-block"><label :for="'bygning'+i.id">Ny anv. kode:</label></span>
                
                <input :id="'bygning'+i.id" type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="card-body pt-2 px-3">
            <p v-if="i.type === ItemType.bygning">
              <ol>
                <li>
                  <span class="text-title">Bebygget areal (m2):</span>
                  {{
                    i.data.byg041BebyggetAreal
                      ? i.data.byg041BebyggetAreal
                      : 'Ukendt areal'
                  }}
                </li>
                <li>
                  <span class="text-title">Opførelsesår:</span>
                  {{ (i.data.byg026Opførelsesår || 'Ukendt år').toString() }}
                </li>
                <li>
                  <span class="text-title">Tagmateriale:</span>
                  {{
                    typeTexts.TAG_KODE[i.data.byg033Tagdækningsmateriale] || 'Ukendt'
                  }}
                </li>
                <li>
                  <span class="text-title">Ydervæggens materiale:</span>
                  {{
                    typeTexts.YdervaeggenesMateriale[i.data.byg032YdervæggensMateriale] || 'Ukendt'
                  }}
                </li>
                <li>
                  <span class="text-title">Varmeinstallation:</span>
                  {{
                    typeTexts.Varmeinstallation[i.data.byg056Varmeinstallation] || 'Ukendt'
                  }}
                </li>
              </ol>
            </p>
          <div v-else>
            <!-- <div class="text">
              <span class="text-title">Etableringsår:</span>
              {{ (i.data.tek024Etableringsår || 'Ukendt år').toString() }}
            </div> -->
            
            <form>
              <div class="form-row">
                <div class="form-group col-10 pr-0">
                  <div class="row">
                    <div class="col pr-2">
                      <label :for="'inputCreateYear1' + index">Etableringsår:</label>
                      <input type="text" :id="'inputCreateYear1' + index" :value="(i.data.tek024Etableringsår || 'Ukendt år').toString()" class="form-control disabled" disabled>
                    </div>
                    <div class="col pl-2">
                      <label :for="'InputDestroyYear1' + index">Evt. sløjfningsår:</label>
                      <input type="text" :id="'InputDestroyYear1' + index" :value="i.data.tek072Sløjfningsår ? i.data.tek072Sløjfningsår.toString() : ''" class="form-control" >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label :for="'radioActive' + index">Driftstatus:</label>
                  <div class="row">
                    <div class="col-4">
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" :name="'radioInUse' + index" :id="'radioActive' + index" value="0">
                        <label class="custom-control-label" :for="'radioActive' + index">
                          I brug
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" :name="'radioInUse' + index" :id="'radioUnactive' + index" value="1">
                        <label class="custom-control-label" :for="'radioUnactive' + index">
                          Ikke i brug
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <template v-if="i.data.tek020Klassifikation === '1110'">
              <form>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="radioLocation">Placering:</label>
                    <div class="row">
                      <div class="col-4">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioLocation' + index" :id="'radioArea' + index" value="0" :checked="i.data.tek027Placering === '2'">
                          <label class="custom-control-label" :for="'radioArea' + index">
                            På grund
                          </label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioLocation' + index" :id="'radioUnderground' + index" value="1" :checked="i.data.tek027Placering === '1'">
                          <label class="custom-control-label" :for="'radioUnderground' + index">
                            Nedgravet
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioLocation' + index" :id="'radioBuilding' + index" value="2" :checked="i.data.tek027Placering === '3' || bygningFromTekniskAnlaeg(i.data)">
                          <label class="custom-control-label" :for="'radioBuilding' + index">
                            I bygning (Nr.):
                          </label>
                          <input type="text" class="form-control" style="max-width: 70px; margin-top: -4px; margin-left: 6px; display: inline-flex; vertical-align: middle;" :value="bygningFromTekniskAnlaeg(i.data)" aria-label="Teknisk anlæge i bygningsnummer tekstfelt">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="text">
                <span class="text-title">Indhold:</span>
                {{ typeTexts.Indhold[i.data.tek034IndholdOlietank] || 'Ukendt' }}
              </div>
              <form>
                <div class="form-row">
                  <div class="form-group col-12">
                    <div class="row">
                      <div class="col-4">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioContain' + index" :id="'radioFO' + index" value="0">
                          <label class="custom-control-label" :for="'radioFO' + index">
                            Fyringsolie
                          </label>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioContain' + index" :id="'radioDS' + index" value="1">
                          <label class="custom-control-label" :for="'radioDS' + index">
                            Diesel
                          </label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioContain' + index" :id="'radioBZ' + index" value="2">
                          <label class="custom-control-label" :for="'radioBZ' + index">
                            Benzin
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="custom-control custom-radio">
                          <input class="custom-control-input" type="radio" :name="'radioContain' + index" :id="'radioOther' + index" value="3">
                          <label class="custom-control-label" :for="'radioOther' + index">
                            Andet
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form>
                <div class="form-row">
                  <div class="form-group col-12 mb-1">
                    <label for="inputSize">Størrelse (L):</label>
                    <input type="text" class="form-control d-inline-block" :id="'inputSize' + index" style="width: 140px; margin-left: 5px;" :value="i.data.tek032Størrelse ? i.data.tek032Størrelse : ''" aria-label="Tank størrelse tekstfelt">
                  </div>
                </div>
                <!-- <div class="form-row">
                  <div class="form-group col-12">
                    <label for="inputEnd">Evt. Sløjfningsår:
                    </label>
                    <input type="text" class="form-control d-inline-block" :id="'inputEnd' + index" style="width: 140px; margin-left: 5px;" :value="i.data.tek072Sløjfningsår ? i.data.tek072Sløjfningsår.toString() : ''">
                  </div>
                </div> -->
              </form>
          </template>
        </div>
          </div> 
          <div class="card-footer pt-0 px-3">
            <div class="custom-text-area">
              <textarea class="form-control" rows="2" aria-label="Fritekst felt"></textarea>
            </div>
          </div>
        </template>
        </div>
      </div>
    </div>
        <div class="pagefooter row">
          <div class="col mr-auto">{{footer}}</div>
          <div class="col text-right">{{index+2}}/{{totalPages}}</div>
        </div>
      </div>

      <div class="printpages pagebreak" :tabindex="totalPages - 1">
        <h1><strong>{{ title }}</strong></h1>
        <h5>{{ subtitle }}</h5>
        <div class="pagetype bg-warning text-dark">Tilføj bygninger/teknisk anlæg der ikke fremgår af BBR </div>
        <div class="container-fluid px-0">
          <div class="card-container row mx-0">
            <!-- CARD - BYGNING FOR LOOP [BEGYND] -->
            <div class="building-card card col-4 mx-0 px-0" v-for="index in ['A','B','C']" :key="index">
              
              <div class="card-header px-3 py-0">
                <div class="building-media">
                  <span class="d-block align-items-center text-center" style="font-size: 29px; font-weight: 600; line-height: 1;">
                    {{index}}
                  </span>
                  <span class="text text-muted d-block pb-1 pt-0" style="width: 30px;"><small style="font-size: 75%;">BYG</small></span>
                </div>
              </div>
              
              <div class="card-body pt-2 px-3">
                <form>
                  <div class="form-row mr-4">
                    <div class="form-group col-12">
                        <div class="row">
                          <div class="col-10 pr-2">
                            <label :for="'inputUse' + index">Anvendelse:</label>
                            <input type="text" :id="'inputUse' + index" class="form-control">
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6 pr-2">
                      <label :for="'inputUseCode' + index">Ny anv. kode:</label>
                      <input type="text" :id="'inputUseCode' + index" class="form-control">
                    </div>
                    <div class="form-group col-12">
                      <div class="row">
                        <div class="col-6 pr-2">
                          <label :for="'inputConstructArea' + index">Bebygget areal (m2):</label>
                          <input type="number" :id="'inputConstructArea' + index" class="form-control">
                        </div>
                        <div class="col-6 pl-2">
                          <label :for="'inputCreateYear1' + index">Opførelsesår:</label>
                          <input type="number" :id="'inputCreateYear1' + index" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <label :for="'inputRoofMaterial' + index">Tagmatriale:</label>
                      <input type="text" :id="'inputRoofMaterial' + index" class="form-control">
                    </div>
                    <div class="form-group col-12">
                      <label :for="'inputOutherMaterial' + index">Ydervægsmatriale:</label>
                      <input type="text" :id="'inputOutherMaterial' + index" class="form-control">
                    </div>
                  </div>
                </form>
              </div>

              <div class="card-footer pt-0 px-3">
                <div class="custom-text-area">
                  <textarea rows="2" class="form-control" aria-label="Fritekst felt"></textarea>
                </div>
              </div>

            </div>
            <!-- CARD - BYGNING FOR LOOP [SLUT] -->
            <!-- CARD - TEKNISK ANLÆG FOR LOOP [BEGYND] -->
            <div class="building-card card col-4 mx-0 px-0" v-for="index in ['D','E','F']" :key="index">
              
              <div class="card-header px-3 py-0">
                <div class="building-media">
                  <span class="d-block align-items-center text-center" style="font-size: 29px; font-weight: 600; line-height: 1;">
                    {{index}}
                  </span>
                  <span class="text text-muted d-block pb-1 pt-0" style="width: 30px;"><small style="font-size: 75%;">TEK</small></span>
                </div>
              </div>
              
              <div class="card-body pt-2 px-3">
                <form>
                  <div class="form-row mr-4">
                    <div class="form-group col-12">
                        <div class="row">
                          <div class="col-11 pr-2">
                            <label :for="'inputType1' + index">Type:</label>
                            <input type="text" :id="'inputType1' + index" class="form-control">
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-10 pr-0">
                      <div class="row">
                        <div class="col pr-2">
                          <label :for="'inputCreateYear2' + index">Etableringsår:</label>
                          <input type="text" :id="'inputCreateYear2' + index" class="form-control">
                        </div>
                        <div class="col pl-2">
                          <label for="InputDestroyYear">Evt. sløjfningsår:</label>
                          <input type="text" class="form-control" aria-label="Evt. sløjfningsår felt">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label :for="'radioActiveCreate' + index">Driftstatus:</label>
                      <div class="row">
                        <div class="col-4">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" :name="'radioInUseCreate' + index" :id="'radioActiveCreate' + index" value="0">
                            <label class="custom-control-label" :for="'radioActiveCreate' + index">
                              I brug
                            </label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" :name="'radioInUseCreate' + index" :id="'radioUnactiveCreate' + index" value="1">
                            <label class="custom-control-label" :for="'radioUnactiveCreate' + index">
                              Ikke i brug
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label for="radioLocation-create">Placering:</label>
                      <div class="row">
                        <div class="col-4">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" :name="'radioLocation-create' + index" :id="'radioArea-create' + index" value="0">
                            <label class="custom-control-label" :for="'radioArea-create' + index">
                              På grund
                            </label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" :name="'radioLocation-create' + index" :id="'radioUnderground-create' + index" value="1">
                            <label class="custom-control-label" :for="'radioUnderground-create' + index">
                              Nedgravet
                            </label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="custom-control custom-radio">
                            <input class="custom-control-input" type="radio" :name="'radioLocation-create' + index" :id="'radioBuilding-create' + index" value="2">
                            <label class="custom-control-label" :for="'radioBuilding-create' + index">
                              I bygning (Nr.):
                            </label>
                            <input type="text" class="form-control" style="max-width: 70px; margin-top: -4px; margin-left: 6px; display: inline-flex; vertical-align: middle;" aria-label="Teknisk anlæge i bygningsnummer tekstfelt">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label :for="'inputContent2' + index">Indhold:</label>
                      <input type="text" :id="'inputContent2' + index" class="form-control">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 mb-1">
                      <label :for="'inputSize2' + index">Størrelse (L):</label>
                      <input type="text" :id="'inputSize2' + index" class="form-control d-inline-block" style="width: 140px; margin-left: 5px;">
                    </div>
                  </div>
                </form>
              </div>

              <div class="card-footer pt-0 px-3">
                <div class="custom-text-area">
                  <textarea rows="2" class="form-control" aria-label="Fritekst felt"></textarea>
                </div>
              </div>

            </div>

          </div>


        </div>

        <div class="pagefooter row">
          <div class="col mr-auto">{{footer}}</div>
          <div class="col text-right">{{totalPages}}/{{totalPages}}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Map from '@/components/Map.vue';
import { EjendomResult } from '@/models/AutocompleteResult';
import { Item, ItemType } from '@/models/Item';
import typeTexts from '@/helpers/data/typeTexts.json';
import { TekniskeAnlaeg, Bygning } from '../helpers/api/DafInterfaces';
import { PositionAndZoom } from '@/models/PositionAndZoom';
import { padLeft } from '@/helpers/UIHelper';
@Component({
  components: {
    Map,
  },
})
export default class PdfPrintComponent extends Vue {
  private deprecatedAnvendelseskode: string[] = ['130', '210', '220', '230', '290', '310', '320', '330', '390', '410', '420', '430', '440', '490', '520', '530'];

  private mapPosition: PositionAndZoom = null;

  private unclick(e: any) {
    const radio = e.srcElement as HTMLInputElement;
    if (radio.checked) {
      radio.checked = false;
    }
  }
  private print() {
    window.print();
  }

  get isWebkit(): boolean {
    return this.$store.getters.isWebkit;
  }

  get typeTexts() {
    return typeTexts;
  }

  get ItemType() {
    return ItemType;
  }
  get hasResult() {
    return this.$store.getters.hasResult;
  }
  get showPrint() {
    return this.hasResult && this.$store.state.showPrint;
  }

  set showPrint(value: boolean) {
    this.$store.commit('showPrint', value);
  }

  get bygningFromTekniskAnlaeg() {
    const vm = this;
    return (item: TekniskeAnlaeg) => {
      const bygn = vm.buildings.find((j: Item) => j.id === item.bygning);
      return bygn ? (bygn.data as Bygning).byg007Bygningsnummer : null;
    };
  }

  get bygningsanvendelser() {
    return this.typeTexts.BYG_ANVEND_KODE;
  }

  get footer(): string {
    const date = new Date();
    return `Bygningsdata fra BBR - Kortdata fra SDFE - Genereret d. ${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${(date.getFullYear())} kl. ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  }

  get totalPages(): number {
    return Math.ceil(this.items.length / 6) + 3;
  }

  get isDeprecated() {
    const vm = this;
    return (kode: string) => {
        return vm.deprecatedAnvendelseskode.indexOf(kode) > -1;
      };
  }

  get items() {
    return [{id: 'owner', data: null}, ...this.$store.getters.items];
  }

  get buildings() {
    return this.$store.getters.items.filter(
      (i: Item) => i.type === ItemType.bygning,
    );
  }

  get selectedEjendom() {
    return this.$store.state.selectedEjendom as EjendomResult;
  }

  get title() {
    const ejendom = this.$store.state.selectedEjendom as EjendomResult;
    let title = ejendom.title;

    if (ejendom.data &&
        ejendom.data.adresseBetegnelse &&
        ejendom.data.adresseBetegnelse.indexOf(ejendom.data.postnr) > -1) {
      title = ejendom.data.adresseBetegnelse;
    } else {
      if (ejendom.data && ejendom.data.vejnavn && ejendom.data.husnummertekst) {
        title = `${ejendom.data.vejnavn} ${ejendom.data.husnummertekst}`;
        if (ejendom.data.etagebetegnelse) {
          title += `, ${ejendom.data.etagebetegnelse}.`;
        }
        if (ejendom.data.dørbetegnelse) {
          title += ` ${ejendom.data.dørbetegnelse}`;
        }
      }

      if (ejendom.data && ejendom.data.postnr && ejendom.data.postdistrikt) {
        title += `, ${ejendom.data.postnr} ${ejendom.data.postdistrikt}`;
      }
    }

    return title;
  }

  get subtitle() {
    const ejendom = this.$store.state.selectedEjendom as EjendomResult;
    let subtitle = '';

    if (ejendom.data.bfeNummer) {
      subtitle += `BFE-nr.: ${ejendom.data.bfeNummer} `;
    }
    // if (this.$store.state.dafResult.ejendomsnummer.length > 0 && this.$store.state.dafResult.ejendomsnummer[0].ejendomsnummer) {
    //   subtitle += `(Kommunalt ejendoms nr.: ${this.$store.state.dafResult.ejendomsnummer[0].kommunekode}-${padLeft(this.$store.state.dafResult.ejendomsnummer[0].ejendomsnummer.toString())})`;
    // }

    return subtitle;
  }

  private isLineBreak(x: string) {
    if (x != '9999')
    return x
    else
    return ''
  }

  private created() {
    if (!this.isWebkit) {
      this.$store.commit('tmpErrorMessage', 'Print funktionen er desværre ikke optimeret til din browser, prøv venligst med en anden browser!');
    }
    window.addEventListener('keyup', this.keyUp, false);

    this.mapPosition = this.$store.state.mapPosition;
  }

  private destroyed() {
    window.removeEventListener('keyup', this.keyUp);
  }

  private keyUp(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      this.showPrint = false;
    }
  }
}
</script>