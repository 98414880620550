import { StrukturUnderType } from './StrukturUnderType';
import { Bygning, TekniskeAnlaeg } from '@/helpers/api/DafInterfaces';

export interface Item {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  icon: StrukturUnderType;
  iconColor: string;
  accuracy: string;
  shortname: string;
  x: number;
  y: number;
  attributes: Attribute[];
  bpfg: boolean;
  ejendomsnummer: number;
  bfeNummer: string;
  showWarning: boolean;
  jordstykke: string;
  data: Bygning | TekniskeAnlaeg;
  type: ItemType;
  sagstype: string;
  tilknytninger: Item[];
  isNybyggeri: boolean;
  isNedrivning: boolean;
  itemImage: any;
}

export interface Attribute {
  name: string;
  value: string;
}

export enum ItemType {
  bygning = 1,
  tekniskanlaeg = 2,
}
