export class StrukturUnderType {
  public navn: string;
  public label: string;
  public kortLabel: string;
  public ikon: string;
  public ikonFont: string;
  public koder: number[];
  public underKoder: number[];

  constructor({
    navn,
    label,
    kortLabel,
    ikon,
    ikonFont,
    koder,
    underKoder,
  }: {
    navn: string;
    label: string;
    kortLabel: string;
    ikon: string;
    ikonFont: string;
    koder: number[];
    underKoder: number[];
  }) {
    this.navn = navn;
    this.label = label;
    this.kortLabel = kortLabel;
    this.ikon = ikon;
    this.ikonFont = ikonFont;
    this.koder = koder;
    this.underKoder = underKoder;
  }
}
