import axios from 'axios';
import {
  BBRSagResult,
  Bygning,
  DafResult,
  DawaFeature,
  FeatureCollection,
  EjendomDetailsResult,
  Ejendomsbeliggenhed,
  Ejendomsnummer,
  IdAndState,
  ObjectHierarchy,
  TekniskeAnlaeg,
  JordstykkeKey,
} from './DafInterfaces';

declare const dafUser: string;
declare const dafPassword: string;
const format = 'format=json&pagesize=9999&page=1';
const dafUrl = 'https://services.datafordeler.dk';
const sgUrl = process.env.VUE_APP_BBR_PROXY;

export default class EjendomSearchApi {
  public static constructSgUrl(action: string, parameters: string): string {
    return `${sgUrl}/${action}?${parameters || ''}`;
  }

  public static constructUrl(action: string, parameters: string): string {
    const token = `username=${dafUser}&password=${dafPassword}`;
    return `${dafUrl}/${action}?${parameters || ''}${
      parameters ? '&' : ''
    }${format}&${token}`;
  }

  public static async getData(
    ejendom: EjendomDetailsResult,
  ): Promise<DafResult> {
    if (!ejendom.type) {
      ejendom.type = 'Ejerlejlighed';
    }

    switch (ejendom.type) {
      case 'Ejerlejlighed':
        return await this.getFromEjerlejlighed(ejendom);
      case 'BygningPaaFremmedGrund':
        return await this.getFromBygningPaaFremmedGrund(ejendom);
      case 'SamletFastEjendom':
      default:
        return await this.getFromSFE(ejendom);
    }
  }

  public static async getFromBygningPaaFremmedGrund(
    ejendom: EjendomDetailsResult,
  ): Promise<DafResult> {
    try {
      const objecthierarchy = await this.getObjectHierarchy(ejendom.bfeNummer);
      const bygninger = objecthierarchy.bygninger as Bygning[];
      const tekniskeAnlæg = objecthierarchy.tekniskeAnlæg as TekniskeAnlaeg[];

      let jordstykke = null;
      if (bygninger.length > 0) {
        jordstykke = bygninger[0].jordstykke;
      }
      if (!jordstykke && tekniskeAnlæg.length > 0) {
        jordstykke = tekniskeAnlæg[0].jordstykke;
      }
      if (!jordstykke) {
        const ejendombelligenhed = await this.getEjendomsbeliggenhed(
          ejendom.bfeNummer,
        );
        if (
          ejendombelligenhed.length > 0 &&
          ejendombelligenhed[0].properties.husnummer.length > 0 &&
          ejendombelligenhed[0].properties.status.startsWith('g')
        ) {
          jordstykke = ejendombelligenhed[0].properties.husnummer[0].jordstykke;
        }
        if (
          !jordstykke &&
          ejendombelligenhed.length > 0 &&
          ejendombelligenhed[0].properties.adresse.length > 0 &&
          ejendombelligenhed[0].properties.status.startsWith('g')
        ) {
          jordstykke =
            ejendombelligenhed[0].properties.adresse[0].husnummer.jordstykke;
        }
      }

      return {
        bygninger,
        grunddataKeys: [{ id: jordstykke }],
        tekniskeAnlæg,
        ejendomsnummer: await this.mapBFEToESR(ejendom.bfeNummer),
        bfeNummer: ejendom.bfeNummer,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  public static async getFromEjerlejlighed(
    ejendom: EjendomDetailsResult,
  ): Promise<DafResult> {
    const objecthierarchy = await this.getObjectHierarchy(ejendom.bfeNummer);
    const bygninger = objecthierarchy.bygninger as Bygning[];
    const tekniskeAnlæg = objecthierarchy.tekniskeAnlæg as TekniskeAnlaeg[];

    const jordstykkeIds = [
      ...new Set(bygninger.map((b) => ({ id: b.jordstykke } as JordstykkeKey))),
    ];
    if (
      jordstykkeIds.length === 0 &&
      tekniskeAnlæg.length === 1 &&
      tekniskeAnlæg[0].tek109Koordinat
    ) {
      const x = tekniskeAnlæg[0].tek109Koordinat.split(' ')[0].split('(')[1];
      const y = tekniskeAnlæg[0].tek109Koordinat.split(' ')[1].split(')')[0];
      const result = await axios.get(
        `https://dawa.aws.dk/jordstykker?x=${x}&y=${y}&srid=25832`,
      );
      if (result.data.length > 0) {
        jordstykkeIds.push({ id: result.data[0].featureid });
      }
    }
    return {
      bygninger,
      grunddataKeys: jordstykkeIds,
      tekniskeAnlæg,
      ejendomsnummer: await this.mapBFEToESR(ejendom.bfeNummer),
      bfeNummer: ejendom.bfeNummer,
    };
  }

  public static async getFromSFE(
    ejendom: EjendomDetailsResult,
  ): Promise<DafResult> {
    const objecthierarchy = await this.getObjectHierarchy(ejendom.bfeNummer);
    const bygninger = objecthierarchy.bygninger as Bygning[];
    const tekniskeAnlæg = objecthierarchy.tekniskeAnlæg as TekniskeAnlaeg[];

    return {
      bygninger,
      grunddataKeys: [{ bfe: ejendom.bfeNummer }],
      tekniskeAnlæg,
      ejendomsnummer: await this.mapBFEToESR(ejendom.bfeNummer),
      bfeNummer: ejendom.bfeNummer,
    };
  }

  public static async getObjectHierarchy(bfeNummer: string): Promise<any> {
    const response = await axios.get(
      this.constructSgUrl('objecthierarchy', `BFENummer=${bfeNummer}`),
    );
    const objecthierarchy = response.data as ObjectHierarchy;
    const bbrsager = await this.getBBRSag(bfeNummer);

    const bygningsIdList: string[] = [];
    const tekniskeAnlaegIdList: string[] = [];
    const enhedIdList: string[] = [];

    if (objecthierarchy.SFE && objecthierarchy.SFE.length) {
      for (const sfe of objecthierarchy.SFE) {
        for (const grund of sfe.Grunde) {
          for (const bygning of grund.Bygninger) {
            bygningsIdList.push(bygning.Id);
            for (const tek of bygning.TekniskeAnlaeg) {
              tekniskeAnlaegIdList.push(tek.Id);
            }
            for (const enhed of bygning.Enheder) {
              enhedIdList.push(enhed.Id);
              for (const tek of enhed.TekniskeAnlaeg) {
                tekniskeAnlaegIdList.push(tek.Id);
              }
            }
          }
          for (const tek of grund.TekniskeAnlaeg) {
            tekniskeAnlaegIdList.push(tek.Id);
          }
        }
      }
    } else if (
      objecthierarchy.Ejerlejlighed &&
      objecthierarchy.Ejerlejlighed.length
    ) {
      for (const sfe of objecthierarchy.Ejerlejlighed) {
        for (const bygning of sfe.Bygninger) {
          bygningsIdList.push(bygning.Id);
          for (const tek of bygning.TekniskeAnlaeg) {
            tekniskeAnlaegIdList.push(tek.Id);
          }
        }
        for (const enhed of sfe.Enheder) {
          enhedIdList.push(enhed.Id);
          bygningsIdList.push(enhed.Bygning.Id);
          for (const tek of enhed.TekniskeAnlaeg) {
            tekniskeAnlaegIdList.push(tek.Id);
          }
        }
        for (const tek of sfe.TekniskeAnlaeg) {
          tekniskeAnlaegIdList.push(tek.Id);
        }
      }
    } else if (objecthierarchy.BPFG && objecthierarchy.BPFG.length) {
      for (const bpfg of objecthierarchy.BPFG) {
        for (const tek of bpfg.TekniskeAnlaeg) {
          tekniskeAnlaegIdList.push(tek.Id);
        }
        for (const bygning of bpfg.Bygninger) {
          bygningsIdList.push(bygning.Id);
          for (const tek of bygning.TekniskeAnlaeg) {
            tekniskeAnlaegIdList.push(tek.Id);
          }
          for (const enhed of bygning.Enheder) {
            enhedIdList.push(enhed.Id);
            for (const tek of enhed.TekniskeAnlaeg) {
              tekniskeAnlaegIdList.push(tek.Id);
            }
          }
        }
      }
    }

    const bygningsIds: IdAndState[] = new Array(...new Set(bygningsIdList)).map(
      (i) => {
        return {
          id: i,
          sagstype: null,
          sagsstatus: null,
          sagsobjectstatus: null,
        };
      },
    );
    const tekniskeAnlaegIds: IdAndState[] = new Array(
      ...new Set(tekniskeAnlaegIdList),
    ).map((i) => {
      return {
        id: i,
        sagstype: null,
        sagsstatus: null,
        sagsobjectstatus: null,
      };
    });

    let bygninger =
      bygningsIds.length > 0
        ? await this.getBygninger(bygningsIds.map((b) => b.id))
        : [];
    let tekniskeAnlæg =
      tekniskeAnlaegIds.length > 0
        ? await this.getTekniskeAnlæg(tekniskeAnlaegIds.map((b) => b.id))
        : [];
    const bygningsidToDelete: string[] = [];
    const tekidsToDelete: string[] = [];
    for (const state of bygningsIds) {
      const bbrsag = bbrsager.find(
        (b) =>
          (b.sagstype == '32' && b.stamdataBygning === state.id) ||
          b.bygningsid === state.id,
      );
      if (bbrsag) {
        state.sagstype = bbrsag.sagstype;
        state.sagsstatus = bbrsag.sagsstatus;
        if (state.sagstype == '32' && bbrsag.bygningsid) {
          const sagsbygning = bygninger.find(
            (b) => b.id_lokalId == bbrsag.bygningsid,
          );
          if (sagsbygning) {
            state.sagsobjectstatus = sagsbygning.status;
            bygningsidToDelete.push(bbrsag.bygningsid);
          }
        }
      } else {
        state.sagstype = null;
        state.sagsstatus = null;
        state.sagsobjectstatus = null;
      }
    }

    for (const id of bygningsidToDelete) {
      bygninger = bygninger.filter((b) => b.id_lokalId != id);
    }

    for (const state of tekniskeAnlaegIds) {
      const bbrsag = bbrsager.find((b) => b.stamdataTekniskAnlæg === state.id);
      if (bbrsag) {
        state.sagstype = bbrsag.sagstype;
        state.sagsstatus = bbrsag.sagsstatus;
        if (state.sagstype == '32' && bbrsag.tekniskanlaegid) {
          const sagstek = tekniskeAnlæg.find(
            (b) => b.id_lokalId == bbrsag.tekniskanlaegid,
          );
          if (sagstek) {
            state.sagsobjectstatus = sagstek.status;
            tekidsToDelete.push(bbrsag.tekniskanlaegid);
          }
        }
      } else {
        state.sagstype = null;
        state.sagsstatus = null;
        state.sagsobjectstatus = null;
      }
    }

    for (const id of tekidsToDelete) {
      tekniskeAnlæg = tekniskeAnlæg.filter((b) => b.id_lokalId != id);
    }

    // tslint:disable-next-line:max-line-length
    bygninger =
      bygningsIds.length > 0
        ? await this.filterAndEnrichBuildings(bygninger, bygningsIds)
        : [];
    // tslint:disable-next-line:max-line-length
    tekniskeAnlæg =
      tekniskeAnlaegIds.length > 0
        ? this.filterAndEnrichTekniskeAnlaeg(tekniskeAnlæg, tekniskeAnlaegIds)
        : [];

    return { bygninger, tekniskeAnlæg };
  }

  public static async getBBRSag(bfeNummer: string): Promise<BBRSagResult[]> {
    const response = await axios.get(
      this.constructSgUrl('bbrsag', `BFENummer=${bfeNummer}`),
    );

    const states: BBRSagResult[] = [];
    for (const bbrsag of response.data as any[]) {
      const bbrsagsstatus = parseInt(bbrsag.status);

      if (
        !(bbrsagsstatus === 5 || bbrsagsstatus === 12 || bbrsagsstatus === 13)
      ) {
        continue;
      }

      for (const niveau of bbrsag.sagsniveauList) {
        if (parseInt(niveau.sagsniveau.status) !== 5) {
          continue;
        }

        states.push({
          sagsstatus: bbrsagsstatus,
          sagstype: niveau.sagsniveau.sagstype,
          bygningsid: niveau.sagsniveau.sagsdataBygning,
          tekniskanlaegid: niveau.sagsniveau.sagsdataTekniskAnlæg,
          stamdataBygning: niveau.sagsniveau.stamdataBygning,
          stamdataTekniskAnlæg: niveau.sagsniveau.stamdataTekniskAnlæg,
        });
      }
    }

    return states;
  }

  public static async getEjendomsbeliggenhed(
    bfeNummer: string,
  ): Promise<Array<DawaFeature<Ejendomsbeliggenhed>>> {
    const response = await axios.get(
      this.constructUrl(
        'EBR/Ejendomsbeliggenhed/1/REST/Ejendomsbeliggenhed',
        `bfenr=${bfeNummer}`,
      ),
    );
    const result = response.data as FeatureCollection<
      DawaFeature<Ejendomsbeliggenhed>
    >;

    return result.features;
  }

  public static async mapBFEToESR(
    bfeNummer: string,
  ): Promise<Ejendomsnummer[]> {
    const response = await axios.get(
      this.constructUrl(
        'BBR/BBRPublic/1/rest/ejendomsrelation',
        `bfenummer=${bfeNummer}`,
      ),
    );

    const ejendomme = (response.data as Array<{
      ejendomsnummer: number;
      kommunekode: string;
      status: string;
    }>)
      .filter(
        (e) =>
          !!e.kommunekode /* && !!e.ejendomsnummer*/ &&
          e.status !== '10' &&
          e.status !== '11',
      )
      .map((r) => ({
        ejendomsnummer: r.ejendomsnummer,
        kommunekode: r.kommunekode,
        kommunenavn: null,
      }));

    const kommuneKoder = [...new Set(ejendomme.map((r) => r.kommunekode))].join(
      '|',
    );
    // tslint:disable-next-line:max-line-length
    const kommuner = (
      await axios.get(
        `https://api.dataforsyningen.dk/kommuner?kode=${kommuneKoder}`,
      )
    ).data as any[];
    for (const ejendom of ejendomme) {
      // tslint:disable-next-line:max-line-length
      const kommune = kommuner.find((k) => k.kode === ejendom.kommunekode);
      ejendom.kommunenavn = kommune.navn;
    }

    return ejendomme;
  }

  public static async mapESRToBFE(
    kommunekode: string,
    ejendomsnummer: string,
  ): Promise<number[]> {
    const response = await axios.get(
      this.constructUrl(
        'BBR/BBRPublic/1/rest/ejendomsrelation',
        `ejendomsnummer=${ejendomsnummer}&kommunekode=${kommunekode}`,
      ),
    );
    return (response.data as Array<{ bfeNummer: number; status: string }>)
      .filter((r) => r.status !== '10' && r.status !== '11' && !!r.bfeNummer)
      .map((r) => r.bfeNummer);
  }

  public static async getTekniskeAnlæg(
    ids: string[],
  ): Promise<TekniskeAnlaeg[]> {
    if (!ids || !ids.length) {
      return [];
    }

    let results: TekniskeAnlaeg[] = [];
    for (let index = 0; index < ids.length; index += 40) {
      const slice = ids.slice(index, index + 40);
      results = results.concat(await this.getTekniskeAnlægCall(slice));

      //await this.delay(500);
    }

    return results;
  }

  public static async getTekniskeAnlægCall(
    ids: string[],
  ): Promise<TekniskeAnlaeg[]> {
    if (!ids || !ids.length) {
      return [];
    }

    const response = await axios.get(
      this.constructUrl(
        'BBR/BBRPublic/1/rest/tekniskanlaeg',
        `id=${ids.join('|')}`,
      ),
    );
    const resultater = response.data as TekniskeAnlaeg[];
    if (resultater) {
      return resultater;
    }

    return [];
  }

  public static async getBygninger(ids: string[]): Promise<Bygning[]> {
    if (!ids || !ids.length) {
      throw Error('Ingen bygninger fundet');
    }
    let results: Bygning[] = [];
    for (let index = 0; index < ids.length; index += 40) {
      const slice = ids.slice(index, index + 40);
      results = results.concat(await this.getBygningerCall(slice));

      //await this.delay(500);
    }

    if (results && results.length) {
      return results;
    }

    throw Error('Ingen bygninger fundet');
  }

  public static async getBygningerCall(ids: string[]): Promise<Bygning[]> {
    if (!ids || !ids.length) {
      return [];
    }

    const response = await axios.get(
      this.constructUrl('BBR/BBRPublic/1/rest/bygning', `id=${ids.join('|')}`),
    );
    const resultater = response.data as Bygning[];
    if (resultater) {
      return resultater;
    }

    return [];
  }

  public static async appendAddress(buildings: Bygning[]): Promise<void> {
    if (!buildings || buildings.length == 0) {
      return;
    }

    const husnumre: string[] = [];
    for (const building of buildings) {
      let husnummer = building.husnummer;
      if (building.opgangList && building.opgangList.length == 1) {
        husnummer = building.opgangList[0].opgang.adgangFraHusnummer;
      }

      if (!husnummer) {
        husnummer = building.husnummer;
      }

      husnumre.push(husnummer);
    }

    const uniquehusnumre = new Array(...new Set(husnumre));

    const result = {};

    for (let i = 0; i < Math.ceil(uniquehusnumre.length / 50); i++) {
      const start = i * 50;
      const end = (i + 1) * 50;
      const husnummer: string = uniquehusnumre.slice(start, end).join('|');

      if (!husnummer) {
        continue;
      }
      try {
        const response = await axios.get(
          this.constructUrl('DAR/DAR/2.0.0/rest/husnummer', `id=${husnummer}`),
        );
        const resultater = response.data as any[];
        if (resultater && resultater.length > 0) {
          for (const res of resultater) {
            result[res.id_lokalId] = res.adgangsadressebetegnelse;
          }
        }
      } catch (error) {
        console.log('Failed to get husnummer');
      }
    }

    for (const building of buildings) {
      let husnummer = building.husnummer;
      if (building.opgangList && building.opgangList.length == 1) {
        husnummer = building.opgangList[0].opgang.adgangFraHusnummer;
      }

      if (!husnummer) {
        husnummer = building.husnummer;
      }

      building.adgangsadressebetegnelse = result[husnummer];
    }
  }

  public static delay(ms: number) {
    return new Promise<void>((resolve, reject) =>
      setTimeout(() => {
        resolve();
      }, ms),
    );
  }

  public static bygningFilter(b: Bygning) {
    // return b.status === '6';
    return (
      (b.status === '2' ||
        b.status === '3' ||
        b.status === '6' ||
        b.status === '12' ||
        b.status === '13') &&
      b.sagstype !== '2' &&
      b.sagstype !== '31'
    );
  }

  public static async filterAndEnrichBuildings(
    buildings: Bygning[],
    states: IdAndState[],
  ): Promise<Bygning[]> {
    for (const building of buildings) {
      const state = states.find((b) => b.id === building.id_lokalId);
      if (state) {
        Object.assign(building, {
          sagstype: state.sagstype,
          sagsstatus: state.sagsstatus,
          sagsobjectstatus: state.sagsobjectstatus,
        });
      }
    }

    const bygninger = buildings.filter(this.bygningFilter);
    const tempArray: Bygning[] = [];
    for (const bygning of bygninger) {
      let found = false;
      for (let index = 0; index < tempArray.length; index++) {
        const temp = tempArray[index];
        if (temp.id_lokalId === bygning.id_lokalId) {
          if (Date.parse(temp.virkningFra) < Date.parse(bygning.virkningFra)) {
            tempArray[index] = bygning;
          }
          found = true;
          break;
        }
      }

      if (!found) {
        tempArray[tempArray.length] = bygning;
      }
    }

    await this.appendAddress(tempArray);

    return tempArray;
  }

  public static filterAndEnrichTekniskeAnlaeg(
    tekniskeAnlaeg: TekniskeAnlaeg[],
    states: IdAndState[],
  ) {
    for (const tek of tekniskeAnlaeg) {
      const state = states.find((b) => b.id === tek.id_lokalId);
      if (state) {
        Object.assign(tek, {
          sagstype: state.sagstype,
          sagsstatus: state.sagsstatus,
          sagsobjectstatus: state.sagsobjectstatus,
        });
      }
    }

    const anlaegFiltered = tekniskeAnlaeg.filter(this.tekniskAnlaegFilter);

    const tempArray: TekniskeAnlaeg[] = [];
    for (const anlaeg of anlaegFiltered) {
      let found = false;
      for (let index = 0; index < tempArray.length; index++) {
        const temp = tempArray[index];
        if (temp.id_lokalId === anlaeg.id_lokalId) {
          if (Date.parse(temp.virkningFra) < Date.parse(anlaeg.virkningFra)) {
            tempArray[index] = anlaeg;
          }
          found = true;
          break;
        }
      }

      if (!found) {
        tempArray[tempArray.length] = anlaeg;
      }
    }

    return tempArray;
  }

  public static tekniskAnlaegFilter(b: TekniskeAnlaeg) {
    return b.status === '6' && b.sagstype !== '2' && b.sagstype !== '31';
  }
}
