import { StrukturHovedType } from '@/models/StrukturHovedType';
import { StrukturUnderType } from '@/models/StrukturUnderType';
import { StrukturUnderTypeMinMax } from '@/models/StrukturUnderTypeMinMax';

const tekniskAnlaeg = new StrukturHovedType({
  navn: 'tekniskanlæg',
  label: 'Teknisk Anlæg',
  kortLabel: 'Tekn.',
  ikon: 'Struktur',
  anvendelser: [
    new StrukturUnderType({
      navn: 'olietank',
      label: 'Tank til olie o. lign.',
      kortLabel: 'Tank til olie o. lign.',
      ikon: 'olietank',
      ikonFont: 'g',
      koder: [1110],
      underKoder: [10, 11, 12, 13, 14, 20, 30, 31],
    }),
    new StrukturUnderType({
      navn: 'tank',
      label: 'Tank til landbrug',
      kortLabel: 'Tank til landbrug',
      ikon: 'tank',
      ikonFont: 'n',
      koder: [1110],
      underKoder: [40, 50, 60, 99],
    }),
    new StrukturUnderType({
      navn: 'silo',
      label: 'Silo eller beholder',
      kortLabel: 'Silo',
      ikon: 'silo',
      ikonFont: 'h',
      koder: [1120],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'biogas',
      label: 'Gasbeholder',
      kortLabel: 'Gasbeholder',
      ikon: 'biogas',
      ikonFont: 'i',
      koder: [1130],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'affaldsbeholder',
      label: 'Affaldsbeholder',
      kortLabel: 'Affaldsbeholder',
      ikon: 'affaldsbeholder',
      ikonFont: 'h',
      koder: [1140],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'vindmoelle',
      label: 'Vindmølle',
      kortLabel: 'Vindmølle',
      ikon: 'vindmoelle',
      ikonFont: 'k',
      koder: [1210],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'energianlaeg',
      label: 'Energianlæg',
      kortLabel: 'E. anlæg',
      ikon: 'energi_anlaeg',
      ikonFont: 'l',
      koder: [1220, 1230, 1240, 1250, 1260, 1270],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'energianlaeg1',
      label: 'Energianlæg',
      kortLabel: 'E. anlæg',
      ikon: 'biogas',
      ikonFont: 'i',
      koder: [1265],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'halmfyr-biogas',
      label: 'Halmfyr eller biogas',
      kortLabel: 'Halmfyr',
      ikon: 'halmfyr_biogas',
      ikonFont: 'i',
      koder: [1275, 1280],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'vandanlaeg',
      label: 'Vandanlæg',
      kortLabel: 'Vandan.',
      ikon: 'vandanlaeg',
      ikonFont: 'j',
      koder: [1310, 1320, 1330, 1340, 1350, 1360],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'andreanlaeg',
      label: 'Andet anlæg',
      kortLabel: 'Andetan.',
      ikon: 'andet_anlaeg',
      ikonFont: 'o',
      koder: [1905, 1910, 1915, 1920, 1925, 1930, 1935, 1940, 1955],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'andreanlaeg3',
      label: 'Andet anlæg',
      kortLabel: 'Andetan.',
      ikon: 'vandanlaeg',
      ikonFont: 'j',
      koder: [1945],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'andreanlaeg2',
      label: 'Andet anlæg',
      kortLabel: 'Andetan.',
      ikon: 'ensilage_planlager',
      ikonFont: 'm',
      koder: [1950],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'ensilage-planlager',
      label: 'Ensilage- eller planlager',
      kortLabel: 'Ensilage-planlager',
      ikon: 'ensilage_planlager',
      ikonFont: 'm',
      koder: [1960, 1965],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'andreanlaeg1',
      label: 'Fortidsminde eller ruin',
      kortLabel: 'Fortidsminde',
      ikon: 'andet_anlaeg',
      ikonFont: 'o',
      koder: [1970],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'ukendttek',
      label: 'Anlæg med ukendt anvendelse',
      kortLabel: 'Anlæg med ukendt anvendelse',
      ikon: 'ukendt',
      ikonFont: 'p',
      koder: [],
      underKoder: [],
    }),
  ],
});
const bygninger = new StrukturHovedType({
  navn: 'bygning',
  label: 'Bygning',
  kortLabel: 'Bygn.',
  ikon: 'hus',
  anvendelser: [
    new StrukturUnderType({
      navn: 'bygning',
      label: 'Bygning til helårsbeboelse',
      kortLabel: 'Bygn.',
      ikon: 'helaarsbeboelse',
      ikonFont: 'r',
      koder: new StrukturUnderTypeMinMax({
        min: 100,
        max: 199,
      }),
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'landbrug',
      label: 'Bygning til landbrug',
      kortLabel: 'Landbrug.',
      ikon: 'landbrug',
      ikonFont: 's',
      koder: new StrukturUnderTypeMinMax({
        min: 210,
        max: 219,
      }),
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'industri',
      label: 'Bygning til produktion',
      kortLabel: 'Industri.',
      ikon: 'industri',
      ikonFont: 'q',
      koder: new StrukturUnderTypeMinMax({
        min: 220,
        max: 299,
      }),
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'erhverv',
      label: 'Bygning til kontor, handel, lager eller transport',
      kortLabel: 'Bygn.',
      ikon: 'erhverv',
      ikonFont: 'u',
      koder: new StrukturUnderTypeMinMax({
        min: 300,
        max: 399,
      }),
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'institution',
      label: 'Bygning til kulturelle formål eller institutioner',
      kortLabel: 'Insti.',
      ikon: 'institution',
      ikonFont: 't',
      koder: new StrukturUnderTypeMinMax({
        min: 400,
        max: 499,
      }),
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'fritidshus',
      label: 'Bygning til fritidsformål',
      kortLabel: 'Fritid.',
      ikon: 'fritidsformaal',
      ikonFont: 'e',
      koder: new StrukturUnderTypeMinMax({
        min: 500,
        max: 599,
      }),
      underKoder: [],
    }),
    // "garage": {
    //    label: "Garage",
    //    kortLabel: "Garage",
    //    ikon: "garage",
    //    koder: [910, 920]
    // },
    new StrukturUnderType({
      navn: 'carport',
      label: 'Carport eller garage',
      kortLabel: 'Carport',
      ikon: 'carport',
      ikonFont: 'f',
      koder: [910, 920],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'udhus',
      label: 'Udhus – skur',
      kortLabel: 'Udhus',
      ikon: 'udehus',
      ikonFont: 'd',
      koder: [930],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'drivhus',
      label: 'Drivhus',
      kortLabel: 'Drivhus',
      ikon: 'drivhus',
      ikonFont: 'd',
      koder: [940],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'fritliggende-overdaekket-areal',
      label: 'Fritliggende overdækket areal',
      kortLabel: 'frit. overd. areal',
      ikon: 'overdaekket_areal',
      ikonFont: 'd',
      koder: [950],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'fritliggende-udestue',
      label: 'Fritliggende udestue',
      kortLabel: 'frit. udestue',
      ikon: 'fritliggende_udestue',
      ikonFont: 'd',
      koder: [960],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'landbrug1',
      label: 'Bygning til landbrug',
      kortLabel: 'Landbrug.',
      ikon: 'landbrug',
      ikonFont: 's',
      koder: [970],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'falde',
      label: 'Faldefærdig Bygning',
      kortLabel: 'Faldefærdig Bygning',
      ikon: 'udehus',
      ikonFont: 'd',
      koder: [990],
      underKoder: [],
    }),
    new StrukturUnderType({
      navn: 'ukendt',
      label: 'Bygning med ukendt anvendelse',
      kortLabel: 'Bygning med ukendt anvendelse',
      ikon: 'ukendt',
      ikonFont: 'c',
      koder: [999],
      underKoder: [],
    }),
  ],
});

export function getTekniskAnlaeg(
  kode: number,
  indholdskode: number,
): StrukturUnderType {
  const teknisk = tekniskAnlaeg.anvendelser.find(
    (a) =>
      a.koder.indexOf(kode) > -1 &&
      (a.underKoder.length === 0 || a.underKoder.indexOf(indholdskode) > -1),
  );
  if (teknisk) {
    return teknisk;
  }

  return tekniskAnlaeg.anvendelser.find((a) => a.koder.length === 0);
}

export function getBygning(kode: number): StrukturUnderType {
  const icon = bygninger.anvendelser.find((a) => a.koder.indexOf(kode) > -1);
  return icon
    ? icon
    : bygninger.anvendelser.find((a) => a.koder.indexOf(999) > -1);
}
