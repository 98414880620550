import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { $$events } from '../events';
import { maskStyle } from './MapStyles';
import store from '@/store';
import { PositionAndZoom } from '@/models/PositionAndZoom';

import './../../../libs/ufst.basemap.min.css';
import 'ol/ol.css';
import {
  Map as UFSTMap,
  VectorBygningLayer,
  OverviewMapLayerSwitcherControl,
  BBRLegendControl,
  Geometry as UFSTGeometry,
} from './../../../libs/ufst.basemap.min.js';

export class MapWrapper {
  public map: Map;
  public ufstMap: any;
  public view: View;

  public topCollection: Collection<Feature> = new Collection<Feature>();
  public topLayerSource = new VectorSource({
    features: this.topCollection,
  });

  public topLayer = new VectorLayer({
    source: this.topLayerSource,
    style: maskStyle,
  });

  private unwatchStore: any[] = [];

  private legend: BBRLegendControl;

  public destroy() {
    if (this.unwatchStore) {
      if (this.unwatchStore) {
        for (let index = 0; index < this.unwatchStore.length; index++) {
          const unwatch = this.unwatchStore[index];
          unwatch();
        }
      }
      this.unwatchStore = null;
    }

    this.view = null;
    if (this.map) {
      this.map.setTarget(null);
    }
    this.map = null;
  }

  public create(
    target: string,
    interactive: boolean,
    startPosition: PositionAndZoom,
  ) {
    if (this.map) {
      return;
    }

    const controls = ['attribution', 'scaleline'];
    if (interactive) {
      this.legend = new BBRLegendControl({
        headingLabel: 'Forklaring til kortet',
      });

      controls.push(new OverviewMapLayerSwitcherControl());
      controls.push(this.legend);
    }

    const position: PositionAndZoom = startPosition
      ? startPosition
      : store.state.mapPosition;
    const newmap = new UFSTMap(target, {
      view: {
        startUpCenter: position ? [position.x, position.y] : null,
        startUpZoom: position ? position.zoom : null,
        useCluster: interactive,
        profile: 'bbr',
        maxZoom: 15,
      },
      layers: [
        'bbr',
        new VectorBygningLayer({
          events: {
            onError: function(errorCode, e) {
              store.commit('tmpErrorMessage', 'Bygningslag kunne ikke hentes');
            },
          },
        }),
        this.topLayer,
      ],
      controls: controls,
      interactions: interactive
        ? {
            enableMapMouseDrag: true,
            enableMouseWheelScroll: true,
            enablePinchRotate: false,
          }
        : {
            enableMapMouseDrag: false,
            enableMouseWheelScroll: false,
            enablePinchRotate: false,
            shiftDragZoom: false,
            enablePinchZoom: false,
            enableDoubleClickZoom: false,
            useCtrlOnMouseWheelZoom: false,
          },
      events: !interactive
        ? null
        : {
            onClick: function(coordinates, e) {
              $$events.$emit('coordinateClicked', coordinates);
            }.bind(this),
            onMarkerClicked: function(markers, e) {
              if (markers && markers.length !== 1) {
                return;
              }

              store.dispatch('setActiveItem', {
                id: markers[0].id,
                click: true,
                map: true,
              });
            }.bind(this),
            onMarkerUnClicked: function(markers, e) {
              store.dispatch('setActiveItem', { id: null, isClick: true });
            }.bind(this),
            onMarkerHovered: function(markers, e) {
              if (markers && markers.length !== 1) {
                return;
              }

              store.dispatch('setActiveItem', {
                id: markers[0].id,
                click: false,
                map: true,
              });
            }.bind(this),
            onMarkerUnHovered: function(markers, e) {
              if (markers && markers.length !== 1) {
                return;
              }

              store.dispatch('setActiveItem', {
                id: null,
                click: false,
                map: true,
              });
            }.bind(this),
            onMapError: function(errorCode, e) {
              store.commit('tmpErrorMessage', 'Fejl under indlæsning af kort');
              console.log(errorCode, e);
            }.bind(this),
            onMapViewChanged: function(centerCoordinate, mapSize, zoomLevel) {
              store.commit('mapPosition', {
                x: precise_round(centerCoordinate[0], 4),
                y: precise_round(centerCoordinate[1], 4),
                zoom: precise_round(zoomLevel, 3),
                extent: view.calculateExtent([mapSize[0], mapSize[1]]),
                extentNoPadding: view.calculateExtent([mapSize[0], mapSize[1]]),
                //resolution: view.getResolution(),
              } as PositionAndZoom);
            }.bind(this),
          },
    });

    this.ufstMap = newmap;
    const map = this.ufstMap.getOLMap();
    const view = map.getView();
    this.view = view;

    this.map = map;

    if (interactive) {
      this.unwatchStore.push(
        store.watch(
          (state, getters) => getters.sideBarLeftPadding,
          (newValue) => {
            this.ufstMap.setPadding([0, 0, 0, newValue]);
            this.ufstMap.setControlContainerClass(
              'ctrl-container c-map-ui text-left' +
                (newValue > 0 ? ' has-sidebar' : ''),
            );
          },
          { immediate: true },
        ),
      );

      this.unwatchStore.push(
        store.watch(
          (state) => state.activeItem,
          (value, oldValue) => {
            if (!value) {
              this.setMarkerHover(null);
              return;
            }
            if (value === oldValue) {
              return;
            }

            this.setMarkerHover(value.id);
          },
          { immediate: true },
        ),
      );

      this.unwatchStore.push(
        store.watch(
          (state) => state.selectedItem,
          (value, oldValue) => {
            if (value === oldValue || !value) {
              return;
            }

            this.setMarkerSelected(value.id);
          },
          { immediate: true },
        ),
      );

      this.unwatchStore.push(
        store.watch(
          (state) => state.searchSuggestion,
          (value) => {
            this.topCollection.clear();

            if (value && value.geometri) {
              this.topCollection.extend(
                new GeoJSON().readFeatures({
                  type: 'Feature',
                  geometry: value.geometri,
                  properties: {
                    type: 'jordstykke',
                  },
                }),
              );
            }
          },
          { immediate: true },
        ),
      );

      this.prepareAndSetGrunddataKeys(interactive);
    } else if (position.extentNoPadding) {
      this.prepareAndSetGrunddataKeys(interactive);
      //this.ufstMap.zoomToExtent(position.extentNoPadding);
      view.fit(position.extentNoPadding, {
        padding: [0, 0, 0, -(store.getters.sideBarLeftPadding * 0.5)],
      });
    }
  }

  public prepareAndSetGrunddataKeys(interactive: boolean) {
    this.unwatchStore.push(
      store.watch(
        (state, getters) => getters.grunddataKeys,
        (grunddataKeys) => {
          if (!grunddataKeys) {
            this.clearMask();
            return;
          }
          if (grunddataKeys != null) {
            this.setGrunddataKeys(grunddataKeys, interactive);
          }
        },
        { immediate: true },
      ),
    );
  }

  public setGrunddataKeys(grunddataKeys: any[], zoom: boolean) {
    this.ufstMap.setGrunddataKeys(grunddataKeys, false, zoom);
  }

  public zoomToItems() {
    this.ufstMap.zoomToMarkers();
  }

  public zoomIn() {
    this.view.animate({
      zoom: this.view.getZoom() + 1,
      duration: 200,
    });
  }

  public zoomOut() {
    this.view.animate({
      zoom: this.view.getZoom() - 1,
      duration: 200,
    });
  }

  public zoomToItem(item: any) {
    const id = item.id;
    this.ufstMap.goToMarker(id);
  }

  public fitToMask() {
    this.ufstMap.zoomToGeometries(this.ufstMap.getMaskGeometry());
  }

  public setMask(features: any[]) {
    const fe = features.map((f: Feature) => new UFSTGeometry(f.getGeometry()));
    this.ufstMap.setMaskAndZoomToGeometries(fe);
  }

  public setMarkers(items: any[]) {
    this.ufstMap.setMarkers(items);

    if (!this.legend) {
      return;
    }

    if (!items || items.length === 0) {
      this.legend.hide();
    } else {
      this.legend.show();
    }
  }

  public setMarkerHover(id: string) {
    this.ufstMap.setMarkerActiveState(id, true, true);
  }

  public setMarkerSelected(id: string) {
    this.ufstMap.setMarkerSelectedState(id, true, true);
  }

  public clearMask() {
    if (!this.ufstMap) {
      return;
    }
    this.ufstMap.clearMask();
  }
}

function precise_round(value: number, decimals: number): number {
  const numSign = value >= 0 ? 1 : -1;

  return parseFloat(
    (
      Math.round(value * Math.pow(10, decimals) + numSign * 0.0001) /
      Math.pow(10, decimals)
    ).toFixed(decimals),
  );
}
