import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import jQuery from 'jquery';
(window as any).jQuery = (window as any).$ = jQuery;
import 'bootstrap';
import './style/bundle.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

/*
 * Accessability - Jonas kode
 */
// Bruger mus
document.body.addEventListener('mousedown', () => {
  document.body.classList.add('using-mouse');
});

// Bruger tastatur - Brugeren tabber
document.body.addEventListener('keydown', (event) => {
  if (event.keyCode === 9) {
    document.body.classList.remove('using-mouse');
  }
});

jQuery('.cpDontAcceptBtn').click(function() {
  const url = 'https://k.bbr.dk/pls/wopdprod/gem_cookiebanner_click?i_svar=N';
  jQuery.ajax({
    url: url,
    type: 'POST',
    dataType: 'text',
    async: false,
    success: function(data) {
      null;
    },
  });
});

jQuery('.cpAcceptBtn').click(function() {
  const url = 'https://k.bbr.dk/pls/wopdprod/gem_cookiebanner_click?i_svar=J';
  jQuery.ajax({
    url: url,
    type: 'POST',
    dataType: 'text',
    async: false,
    success: function(data) {
      null;
    },
  });
});
