declare const $: any;

export function placeElementInViewport(
  scrollView: HTMLDivElement,
  resultList: HTMLDivElement,
  el: HTMLElement,
) {
  const scrollViewRect = scrollView.getBoundingClientRect();
  const elementRect = el.getBoundingClientRect();

  const top = resultList.offsetTop - scrollView.offsetTop;
  const offSet = top + el.offsetTop;
  const itemBottom = offSet + elementRect.height;

  const listPosition = scrollViewRect.height + scrollView.scrollTop;

  if (itemBottom > listPosition) {
    $(scrollView).animate(
      {
        scrollTop: offSet - (scrollViewRect.height - elementRect.height),
      },
      200,
    );
  } else if (offSet < scrollView.scrollTop) {
    $(scrollView).animate(
      {
        scrollTop: offSet,
      },
      200,
    );
  }
}

export function isMobileSetup(): boolean {
  return window.innerWidth < 800;
}

export function padLeft(text: string) {
  return '000000'.substring(text.length) + text;
}

export function groupBy<TItem>(
  xs: TItem[],
  key: string,
): { [key: string]: TItem[] } {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
