<template>
  <nav
    class="c-sidebar col-auto bg-primary bg-white sidebar p-0 text-left l-ui-shadow"
    style="width:400px;"
    :style="{ transform: showSidebar ? 'translateX(0)' : 'translateX(-100%)' }"
    role="main"
    aria-labelledby="sidebar"
  >
    <div class="c-sidebar-header">
      <Header />
      <!-- Header Component -->
      <div
        class="row no-gutters px-3 pb-3"
        style="position: relative; z-index: 100;"
      >
        <div class="col-12 text-center">
          <div class="c-search-container w-100 mt-2 mb-2">
            <SearchComponent />
          </div>
        </div>
      </div>
    </div>
    <div
      id="main-content"
      class="l-scoll-style is-loading"
      :class="{ 'has-scrolled': hasScrolled, 'is-loading': isLoading }"
      style="height: calc(100% - 150px);"
      @scroll="onScroll"
      ref="scrollView"
    >
      <div v-if="isLoading" class="is-loading-overlay">
        <div class="is-loading-overlay__container">
          <span class="is-loading__spinner"></span>
          <p>
            <strong>Henter data</strong>
          </p>
          <p class="text-muted">Vent venligst</p>
        </div>
      </div>
      <div
        class="alert alert-danger alert--list mt-3"
        role="alert"
        v-if="errorMessage"
        v-html="errorMessage"
      ></div>
      <div v-if="hasResult" class="l-result-content ufst-marker-fonts">
        <!-- style="height: 100%;" -->
        <div class="c-sidebar-content" style="height: 100%;">
          <div class="p-3 pb-4" style="border-top: 1px solid black;">
            <button
              type="button"
              class="c-go-back btn btn-link mb-2"
              @click="backToResult"
              v-if="showBackToResult && !hasMultipleBfe"
            >
              <img
                src="./../assets/ikoner/pointer.svg"
                alt="Pil højre ikon"
              />{{
                hasMultipleBfe
                  ? 'Vis BFE-numre på ejendom'
                  : 'Tilbage til resultat'
              }}
            </button>
            <h1
              class="card-title mb-1 mt-2 text-success"
              style="padding-left: 3px;"
            >
              {{ headerinfo.title }}
            </h1>
            <!-- <p>{{headerinfo.typeTekst}}</p>
            <p v-if="headerinfo.isModerEjendom">Moderejendom</p>-->
            <div class="c-metadata mb-0" style="padding-left: 3px;">
              <p v-if="headerinfo.subtitle" class="mt-0 mb-2">
                {{ headerinfo.subtitle }}
              </p>
              <p class="text-important mb-1">
                <span
                  v-if="headerinfo.isModerEjendom"
                  class="d-inline-block text-important-list-text"
                  >Moderejendom for ejerlejligheder</span
                >
                <span
                  class="d-inline-block text-important-list-text"
                  v-if="headerinfo.typeTekst"
                  >{{ headerinfo.typeTekst }}</span
                >
              </p>
              <p v-for="attr in headerinfo.attributes" :key="attr.id">
                <strong v-if="attr.name">{{ attr.name }}:</strong>
                {{ attr.value }}
              </p>
            </div>
            <!-- <span class="badge badge-secondary mr-1">{{headerinfo.typeTekst}}</span> -->
            <div class="card-links mt-3 mb-2" style="padding-left: 3px;">
              <!-- <template v-if="!bbrMeddelelseLinks || bbrMeddelelseLinks.length !== 1"> -->
              <!-- <template v-for="bbrLink in bbrMeddelelseLinks"> -->
              <a
                :key="'Vis BBR-meddelelse'"
                :href="bbrMeddelelseLink"
                target="_blank"
                title="Se BBR meddelesen for ejendommen (Åbner i nyt vindue)"
                rel="noreferrer"
              >
                <img
                  src="./../assets/ikoner/icon-download--green.svg"
                  alt="Søgeloop og dokument"
                  style="height: 14px; width: 14px;"
                  class="mr-2"
                />{{ 'Vis BBR-meddelelse' }}
              </a>
              <br :key="'Vis BBR-meddelelse' + 'br'" />
              <!-- </template> -->
              <a
                :href="bbrOplysningerLinks"
                target="_blank"
                title="Søg efter BBR-oplysninger (Åbner i nyt vindue)"
                rel="noreferrer"
              >
                <img
                  src="./../assets/images/icon-sog-bbr.svg"
                  alt="Søgeloop og dokument"
                  style="height: 14px; width: 14px;"
                  class="mr-2"
                />Find alle BBR-oplysninger
              </a>
              <br />
              <!-- </template> -->
              <a
                href="http://ret.bbr.dk"
                target="_blank"
                title="Gå til Ret BBR (Åbner i nyt vindue)"
                rel="noreferrer"
              >
                <img
                  src="./../assets/ikoner/icon-ret-bbr--green.svg"
                  alt="Blyant og dokument"
                  style="height: 14px; width: 14px;"
                  class="mr-2"
                />Ret i dine BBR-oplysninger
              </a>
              <br />
              <a
                :href="getMapPosition"
                target="_blank"
                title="Gå til Skråfotos (Åbner i nyt vindue)"
                rel="noreferrer"
                v-if="getMapPosition !== null"
              >
                <img
                  src="./../assets/ikoner/icon-location--green.svg"
                  alt="Lokation"
                  style="height: 14px; width: 14px;"
                  class="mr-2"
                />Se ejendom i Skråfotos
                <br />
              </a>

              <a
                @click.prevent="printPdf"
                href="#"
                title="Henter PDF-kort"
                rel="noreferrer"
                v-if="hasResult"
              >
                <img
                  src="./../assets/ikoner/icon-download--green.svg"
                  alt="download"
                  style="height: 12px; width: 14px;"
                  class="mr-2"
                />Hent kortudsnit som PDF
              </a>
            </div>
          </div>
          <div class="sidebar-sticky">
            <div
              class="alert alert--list alert-danger"
              role="alert"
              v-if="!items.length"
            >
              Der er ikke registreret nogen bygninger på den valgte ejendom i
              BBR. Derfor vises der ikke nogen bygningsoplysninger for bygninger
              på denne ejendom.
            </div>
            <div
              class="c-list list-group"
              ref="itemList"
              tabindex="0"
              @focus="focus"
              @blur="blur"
            >
              <template
                v-for="(mainitem, mainitemIndex) in mainitems"
                :id="'item_' + mainitem.id"
              >
                <div
                  v-if="
                    hasMultipleBfe &&
                      (mainitemIndex == 0 ||
                        mainitem.bfeNummer !==
                          mainitems[mainitemIndex - 1].bfeNummer)
                  "
                  class="list-group-item list-group-item-headline flex-column align-items-start p-2 pl-3"
                  :key="mainitem.id + '_bfe'"
                >
                  <strong>Bestemt fast ejendom (BFE):</strong>
                  {{ mainitem.bfeNummer }}
                </div>
                <div
                  v-for="(item, itemindex) in [
                    mainitem,
                    ...(mainitem.tilknytninger || []),
                  ]"
                  class="list-group-item list-group-item-action flex-column align-items-start p-0"
                  :key="item.id + itemindex"
                  :class="[
                    {
                      'is-active': item == activeItem,
                      'is-selected': item == selectedItem,
                      'list-group-item-tilknytning': itemindex > 0,
                      'list-group-item-hastilknytning':
                        !!mainitem.tilknytninger && itemindex == 0,
                      'list-group-item-firstaftertilknytning':
                        mainitemIndex > 0 &&
                        !!mainitems[mainitemIndex - 1].tilknytninger &&
                        itemindex == 0,
                    },
                    item.accuracy,
                  ]"
                  :id="'item_' + item.id"
                >
                  <div
                    class="list-group-item-container p-3"
                    @mouseenter="itemEnter(item)"
                    @mouseleave="itemLeave(item)"
                    @click="selectItem(item)"
                  >
                    <div class="row no-gutters w-100">
                      <div class="col-auto pr-3">
                        <span
                          class="icon d-flex align-items-center text-center"
                        >
                          <!-- <i
                          class="icon-BBR"
                          
                          :style="{ color: item.iconColor }"
                         > -->
                          <img
                            :src="item.itemImage.src"
                            :alt="item.itemImage.alt"
                            :title="item.itemImage.title"
                            style=" height: 32px; width: 32px;padding-left: 2px;"
                            class="ufst-icon-bbr icon d-flex align-items-center text-center"
                          />
                          <!-- v-html="item.itemImage.outerHTML" -->
                          <!-- </i> -->
                        </span>
                      </div>
                      <div class="col">
                        <h5 class="mb-0">
                          <strong :title="item.data.status"
                            >{{ item.subtitle
                            }}<span v-if="item.bpfg">*</span>:</strong
                          >
                          {{ item.title }}
                        </h5>
                        <div class="c-metadata mt-2">
                          <p v-if="item.description">
                            {{ item.description }}
                          </p>
                        </div>
                        <div
                          v-if="item.accuracy == 'usikker'"
                          class="alert alert--list alert-warning mb-2 mt-2"
                          role="alert"
                        >
                          <div class="row no-gutters align-items-center">
                            <div class="col col-auto pr-2">
                              <img
                                src="./../assets/ikoner/BBR-advarsel.svg"
                                alt="BBR Advarleses trekant ikon"
                              />
                            </div>
                            <div class="col">Ikke sikkert placeret</div>
                          </div>
                        </div>

                        <div class="c-metadata mt-2">
                          <span
                            v-if="item.isNybyggeri"
                            class="badge badge--casetype mb-2"
                          >
                            <!-- :style="{'background-color': item.iconColor}" -->
                            NYBYGGERI
                          </span>
                          <span
                            v-else-if="item.isNedrivning"
                            class="badge badge--casetype mb-2"
                          >
                            NEDRIVNING
                          </span>

                          <p v-for="attr in item.attributes" :key="attr.name">
                            <strong>{{ attr.name }}:</strong>
                            {{ attr.value }}
                          </p>
                        </div>

                        <div
                          v-if="item.bpfg"
                          class="c-metadata c-metadata--section mt-2 mb-2 alert alert-info"
                        >
                          <div class="row no-gutters align-items-center my-2">
                            <div class="col">
                              <h6 class="heading">
                                * Bygning på fremmed grund
                              </h6>
                              <p style="margin-left: .85em;">
                                <strong>BFE-nr.:</strong>
                                {{ item.ejendomsnummer }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="l-welcome-content" v-else>
        <div class="c-sidebar-content">
          <WelcomeComponent v-if="!ejendomme" />
          <EjendomSelectorComponent v-else />
        </div>
      </div>
      <!-- <button
        type="button"
        class="c-to-top btn btn-success px-4 l-ui-shadow"
        style="opacity: 0; pointer-events: none;"
        :disabled="!hasScrolled"
      >
        Gå til top
      </button>-->
      <div class="c-sidebar-footer">
        <Footer />
        <!-- Footer Component -->
      </div>
    </div>
    <!-- Collapse Sidebar Component -->
    <button
      type="button"
      class="c-sidebar__btn btn pl-2 border-left"
      :class="{ 'has-sidebar': showSidebar }"
      style="top: 75px; box-shadow: 3px 2px 4px 0px rgba(0, 0, 0, 0.25);"
      @click="showSidebar = !showSidebar"
      title="Skjul/vis indhold (Ctrl + b)"
    >
      <img
        v-if="showSidebar"
        src="./../assets/ikoner/icon-chevron-left.svg"
        alt="Venstre pil ikon"
        style="height: 10px"
      />
      <img
        v-else
        src="./../assets/ikoner/icon-chevron-right.svg"
        alt="Højre pil ikon"
        style="height: 10px"
      />
    </button>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import { $$events } from './../helpers/events';
import { Item, ItemType } from './../models/Item';
import { groupBy, placeElementInViewport } from '@/helpers/UIHelper';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import SearchComponent from './SearchComponent.vue';
import EjendomSelectorComponent from './EjendomSelector.vue';
import WelcomeComponent from './Welcome.vue';
import { Ejendomsnummer } from '@/helpers/api/DafInterfaces';
import { getMarkerIcon } from './../../libs/ufst.basemap.min.js';

@Component({
  computed: {
    ...mapState(['selectedItem', 'isLoading']),
    ...mapGetters(['headerinfo', 'hasResult']),
  },
  components: {
    SearchComponent,
    Header,
    Footer,
    EjendomSelectorComponent,
    WelcomeComponent,
  },
})
export default class Sidebar extends Vue {
  private hasScrolled: boolean = false;
  private resultsHasFocus: boolean = false;
  private selectedItem!: Item;

  private onScroll(event: Event) {
    if (event.target) {
      this.hasScrolled = (event.target as HTMLDivElement).scrollTop > 0;
    }
  }

  get errorMessage(): boolean {
    return this.$store.state.errorMessage;
  }

  get getMapPosition(): string {
    const husnummerPosition: string = this.$store.getters
      .propertyHusnummerPosition;
    const mapPosition = this.$store.getters.mapPosition;
    if (husnummerPosition != null) {
      let pos = husnummerPosition
        .split('(')[1]
        ?.split(')')[0]
        ?.split(' ')
        .map((i) => Number(i));
      if (pos !== null) {
        return (
          'https://skraafoto.dataforsyningen.dk/?orientation=north&center=' +
          (pos[0] + ',' + pos[1])
        );
      }
    }

    return null;
  }

  get bbrMeddelelseLink(): string {
    return (
      'https://bbr.dk/pls/wwwdata/get_newois_pck.show_bbr_meddelelse_pdf?i_bfe=' +
      this.distinctBFEs.join(',').replace(/\s/g, '')
    );
  }

  get bbrMeddelelseLinks(): any[] {
    let esr = this.$store.getters.esrs as Ejendomsnummer[];
    if (!esr) {
      esr = [];
    }

    return esr.map((e) => {
      return {
        link: `https://bbr.dk/pls/wwwdata/get_ois_pck.show_bbr_meddelelse_pdf?i_municipalitycode=${e.kommunekode}&i_realpropertyidentifier=${e.ejendomsnummer}`,
        title: `Vis BBR-meddelelse${
          esr.length > 1 ? ` (${e.kommunekode} ${e.ejendomsnummer})` : ''
        }`,
      };
    });
  }

  get bbrOplysningerLinks(): string {
    return (
      'https://bbr.dk/se-bbr-oplysninger/0/1/0/bfe:' +
      this.distinctBFEs.join(',').replace(/\s/g, '')
    );
  }

  get distinctBFEs(): any[] {
    let bfes: any[] = [];
    this.$store.getters.headerinfo.attributes.forEach((element) => {
      if (element.name == 'BFE-nr.') {
        bfes.push(element.value);
      }
    });

    return bfes;
  }

  get baseUrl() {
    return process.env.BASE_URL;
  }

  get ejendomme(): any[] {
    return this.$store.getters.ejendomme;
  }

  get selectedEjendom(): any {
    return this.$store.state.selectedEjendom;
  }

  get showBackToResult(): boolean {
    return (
      this.ejendomme &&
      this.ejendomme.length > 0 &&
      this.selectedEjendom &&
      (this.selectedEjendom.source !== 'daf.ejendomsnummer' ||
        this.selectedEjendom.resultType !== 4)
    );
  }

  private printPdf() {
    this.$store.commit('showPrint', true);
  }

  private backToResult() {
    this.$store.dispatch('search', null);
  }

  private selectItem(item: Item) {
    this.$store.dispatch('setActiveItem', {
      id: item.id,
      click: true,
    });
  }

  private itemEnter(item: Item) {
    this.$store.dispatch('setActiveItem', {
      id: item.id,
      click: false,
    });
  }

  private itemLeave(item: Item) {
    this.$store.dispatch('setActiveItem', {
      id: null,
      click: false,
    });
  }

  get items(): Item[] {
    if (!this.hasMultipleBfe) {
      return this.$store.getters.items;
    }

    const bfegroups = groupBy<Item>(this.$store.getters.items, 'bfeNummer');
    let items: Item[] = [];

    for (const bfe of this.bfenumbers) {
      items = items.concat(bfegroups[bfe]);
    }

    items.forEach((item) => {
      const marker = this.createMarkerFromItem(item);
      item.itemImage = getMarkerIcon(marker);
    });
    return items;
  }

  get mainitems(): Item[] {
    this.items.forEach((item) => {
      const marker = this.createMarkerFromItem(item);
      item.itemImage = getMarkerIcon(marker);
    });
    return this.items
      .filter((i) => !i.tilknytninger || i.type == ItemType.bygning)
      .sort((a, b) => {
        if (a.bfeNummer > b.bfeNummer) {
          return 1;
        }

        if (a.bfeNummer < b.bfeNummer) {
          return -1;
        }

        return 0;
      });
  }

  get bfenumbers(): Set<string> {
    return new Set(this.$store.getters.items.map((i) => i.bfeNummer));
  }

  get hasMultipleBfe(): boolean {
    return this.bfenumbers.size > 1;
  }

  get selectedId(): string {
    return this.$store.state.selectedId;
  }

  get activeItem(): Item {
    return this.$store.state.activeItem;
  }

  @Watch('items', { immediate: true })
  private onItemsChanged(items: Item[]) {
    const view = this.$refs.scrollView as HTMLDivElement;
    if (view) {
      view.scrollTop = 0;
    }
    this.hasScrolled = false;
    if (items && items.length > 0 && this.selectedId) {
      this.$nextTick(() => {
        this.$store.dispatch('setActiveItem', {
          id: this.selectedId,
          click: true,
        });
      });
    }
  }

  get showSidebar(): boolean {
    return this.$store.state.showSidebar;
  }

  set showSidebar(value: boolean) {
    this.$store.commit('showSidebar', value);
  }

  private mounted() {
    $$events.$on('goToItem', this.goToItem);
    document.addEventListener('keydown', this.keyPressed);
  }

  private beforeDestroy() {
    $$events.$off('goToItem', this.goToItem);
    document.removeEventListener('keydown', this.keyPressed);
  }

  private focus(e) {
    this.resultsHasFocus = true;
  }

  private blur(e) {
    this.resultsHasFocus = false;
  }

  get hasKeyboardNavigation(): boolean {
    return (
      this.showSidebar &&
      this.$store.getters.hasResult &&
      !this.$store.getters.hasModal &&
      this.resultsHasFocus
    );
  }

  private keyPressed(e: KeyboardEvent) {
    const isInput =
      (e.target as HTMLElement).nodeName === 'INPUT' ||
      (e.target as HTMLElement).nodeName === 'TEXTAREA';
    if (isInput || !this.hasKeyboardNavigation) {
      return;
    }
    switch (e.keyCode) {
      case 38: // pil op
        this.$store.dispatch('nextItem', -1);
        if (this.activeItem) {
          this.goToSelectedOrActiveItem(this.activeItem, false);
        }
        e.preventDefault();
        break;
      case 40: // pil ned
        this.$store.dispatch('nextItem', 1);
        if (this.activeItem) {
          this.goToSelectedOrActiveItem(this.activeItem, false);
        }
        e.preventDefault();
        break;
      case 13: // enter
        if (this.activeItem) {
          this.selectItem(this.activeItem);
        }
        e.preventDefault();
        break;
    }
  }

  private goToItem(item: Item) {
    this.goToSelectedOrActiveItem(item, true);
  }

  private goToSelectedOrActiveItem(item: Item, selected: boolean) {
    const vm = this;
    this.$nextTick(() => {
      const div = this.$refs.itemList as HTMLDivElement;
      const view = this.$refs.scrollView as HTMLDivElement;
      if (div && item) {
        const element = div.querySelector('#item_' + item.id) as HTMLElement;

        if (element) {
          placeElementInViewport(view, div, element);
        }
      }
    });
  }

  private createMarkerFromItem(item: Item) {
    return {
      id: item.id,
      title: item.icon.label,
      icon: item.icon.ikonFont,
      color: item.iconColor,
      shortname: item.shortname,
      x: item.x,
      y: item.y,
      warning: item.showWarning,
      label: item.isNybyggeri
        ? 'NYBYGGERI'
        : item.isNedrivning
        ? 'NEDRIVNING'
        : null,
      selected: false,
      type: item.type
        ? item.type === ItemType.bygning
          ? 'bygning'
          : 'tekniskanlaeg'
        : null,
    };
  }
}
</script>
