import Vue from 'vue';
import Vuex from 'vuex';
import router from './../router/index';
import { SetActiveItemArgs } from '@/models/Arguments';
import { $$events } from '@/helpers/events';
import { Item, Attribute, ItemType } from '@/models/Item';
import { StrukturUnderType } from '@/models/StrukturUnderType';
import { getTekniskAnlaeg, getBygning } from '@/helpers/itemTypes';
import { PositionAndZoom } from '@/models/PositionAndZoom';
import EjendomSearchApi from '@/helpers/api/DafApi';
import {
  TekniskeAnlaeg,
  Bygning,
  DafResult,
  JordstykkeKey,
  BfeKey,
  MatrikelKey,
} from '@/helpers/api/DafInterfaces';
import {
  SearchComponentResult,
  EjendomResult,
} from '@/models/AutocompleteResult';
import WKT from 'ol/format/WKT';
import typeTexts from '@/helpers/data/typeTexts.json';
import { isMobileSetup } from '@/helpers/UIHelper';
import { Point } from 'ol/geom';

Vue.use(Vuex);

const isWebkit = /WebKit/.test(navigator.userAgent);

export default new Vuex.Store({
  state: {
    searchResult: null,
    dafResult: null,
    activeItem: null,
    selectedItem: null,
    showSidebar: true,
    selectedId: null,
    showAbout: false,
    errorMessage: null,
    tmpErrorMessage: null,
    searchSuggestion: null,
    showLegend: !isMobileSetup(),
    mapPosition: null,
    propertyHusnummerPosition: null,
    searchComponentResult: null,
    isLoading: false,
    selectedEjendom: null,
    showPrint: false,
    activeMapLayer: null,
  },
  getters: {
    sideBarLeftPadding(state: any): number {
      return state.showSidebar && !isMobileSetup() ? 400 : 0;
    },
    isWebkit(state: any): boolean {
      return isWebkit;
    },
    ejendomme(state: any): [] {
      if (!state.searchComponentResult) {
        return null;
      }

      return state.searchComponentResult.data;
    },
    selectedId(state: any): boolean {
      return state.selectedId;
    },
    hasResult(state: any): boolean {
      return !!state.dafResult && !!state.selectedEjendom;
    },
    hasModal(state: any): boolean {
      return state.showAbout;
    },
    headerinfo(state: any): any {
      const ejendom = state.selectedEjendom as EjendomResult;
      if (!state.dafResult || !ejendom) {
        return null;
      }
      let title = ejendom.title;
      let subtitle = null;
      const typeTekst =
        ejendom.data && ejendom.data.type !== 'SamletFastEjendom'
          ? ejendom.data.typeTekst
          : null;
      const isModerEjendom = ejendom.data ? ejendom.data.isModerEjendom : false;

      if (ejendom.data && ejendom.data.vejnavn && ejendom.data.husnummertekst) {
        title = `${ejendom.data.vejnavn} ${ejendom.data.husnummertekst}`;
        if (ejendom.data.etagebetegnelse) {
          title += `, ${ejendom.data.etagebetegnelse}.`;
        }
        if (ejendom.data.dørbetegnelse) {
          title += ` ${ejendom.data.dørbetegnelse}`;
        }
      }

      if (ejendom.data && ejendom.data.postnr && ejendom.data.postdistrikt) {
        subtitle = `${ejendom.data.postnr} ${ejendom.data.postdistrikt}`;
      }

      const attributes = [];
      const bfe = [];
      let attrIndex = 0;
      if (ejendom.data.bfeNummer) {
        bfe.push(ejendom.data.bfeNummer);
      }

      if (ejendom.datatype === 'ejendomsrelation') {
        // /*ejendom.source==='daf.ejendomsnummer'*/ && ejendom.resultType == 4){

        subtitle = null; // ejendom.description;
        if (ejendom.title === 'Alle SFE') {
          // TODO: find bedre fix end kig på titel
          subtitle = null;
        }

        title = null;
        try {
          const ejendomme = (ejendom.data as unknown) as any[];
          if (ejendomme && ejendomme.length > 0) {
            title = ejendomme[0].title;
          }

          bfe.push(...ejendomme.map((a) => a.data.bfeNummer));
        } catch (error) {
          console.log('error');
        }
      }

      if (!title) {
        title = ejendom.title;
      }

      if (bfe && bfe.length) {
        const bfeSorted = bfe.sort((a, b) => a - b);
        attributes.push({
          id: attrIndex++,
          name: 'BFE-nr.',
          value: bfeSorted.join(', '),
        });
      }

      const numbers = {};
      if (state.dafResult.ejendomsnummer) {
        for (const ejendomsnummer of state.dafResult.ejendomsnummer) {
          const key =
            ejendomsnummer.kommunekode.length === 4
              ? `${
                  ejendomsnummer.kommunenavn
                } (${ejendomsnummer.kommunekode.slice(1)})`
              : `${ejendomsnummer.kommunenavn} (${ejendomsnummer.kommunekode})`;
          if (!numbers[key]) {
            numbers[key] = [];
          }
          if (ejendomsnummer.ejendomsnummer) {
            numbers[key].push(
              `${ejendomsnummer.kommunekode} ${ejendomsnummer.ejendomsnummer}`,
            );
          }
        }
      }

      for (const key in numbers) {
        if (Object.prototype.hasOwnProperty.call(numbers, key)) {
          const element = numbers[key];
          // if (element && element.length) {
          //   attributes.push({
          //     id: attrIndex++,
          //     name: 'Kommunalt ejendoms nr.',
          //     value: element.join(', '),
          //   });
          // }
          attributes.push({
            id: attrIndex++,
            name: 'Kommune',
            value: key,
          });
        }
      }

      return {
        title,
        subtitle,
        typeTekst,
        isModerEjendom,
        attributes,
      };
    },
    grunddataKeys(state: any): (JordstykkeKey | BfeKey | MatrikelKey)[] {
      if (!state.dafResult) {
        return null;
      }

      return state.dafResult.grunddataKeys;
    },
    mapPosition(state: any) {
      return state.mapPosition;
    },
    propertyHusnummerPosition(state: any) {
      return state.propertyHusnummerPosition;
    },
    esr(state: any) {
      if (
        !state.dafResult ||
        !state.dafResult.ejendomsnummer ||
        state.dafResult.ejendomsnummer.length !== 1
      ) {
        return null;
      }

      return state.dafResult.ejendomsnummer[0];
    },
    esrs(state: any) {
      if (!state.dafResult || !state.dafResult.ejendomsnummer) {
        return [];
      }

      return state.dafResult.ejendomsnummer;
    },
    items(state: any): any[] {
      if (!state.dafResult) {
        return [];
      }

      const items: Item[] = [];
      for (const bygning of state.dafResult.bygninger as Bygning[]) {
        const attributes: Attribute[] = [
          {
            name: 'Opførelsesår',
            value: `${(
              bygning.byg026Opførelsesår || 'Ukendt år'
            ).toString()} | ${
              bygning.byg041BebyggetAreal
                ? bygning.byg041BebyggetAreal + ' m2'
                : 'Ukendt areal'
            }`,
          },
        ];

        const tagTekst =
          typeTexts.TAG_KODE[bygning.byg033Tagdækningsmateriale] || 'Ukendt';
        attributes.push({
          name: 'Tagdækning',
          value: tagTekst,
        });

        const ydervaegTekst =
          typeTexts.YdervaeggenesMateriale[
            bygning.byg032YdervæggensMateriale
          ] || 'Ukendt';

        attributes.push({
          name: 'Ydervægge',
          value: ydervaegTekst,
        });

        const varmeTekst =
          typeTexts.Varmeinstallation[bygning.byg056Varmeinstallation] ||
          'Ukendt';

        attributes.push({
          name: 'Varmeinstallation',
          value: varmeTekst,
        });

        if (
          bygning.byg056Varmeinstallation === '2' ||
          bygning.byg056Varmeinstallation === '3' ||
          bygning.byg056Varmeinstallation === '6'
        ) {
          attributes.push({
            name: 'Opvarmningsmiddel',
            value:
              typeTexts.Opvarmningsmiddel[bygning.byg057Opvarmningsmiddel] ||
              'Ukendt',
          });
        }
        if (bygning.byg058SupplerendeVarme) {
          attributes.push({
            name: 'Supplerende varme',
            value:
              typeTexts.SupplerendeVarme[bygning.byg058SupplerendeVarme] ||
              'Ukendt',
          });
        }

        let icon: StrukturUnderType = null;
        let iconColor: string = '#008100';
        let accuracy: string = 'sikker';

        switch (bygning.byg134KvalitetAfKoordinatsæt) {
          case '1':
            iconColor = '#008100';
            accuracy = 'sikker';
            break;
          default:
            iconColor = '#db0101';
            accuracy = 'usikker';
            break;
        }

        icon = getBygning(parseInt(bygning.byg021BygningensAnvendelse, 10));
        const format = new WKT();

        let coordinates = null;
        if (bygning.byg404Koordinat) {
          const punkt = format.readFeature(bygning.byg404Koordinat, {
            dataProjection: 'EPSG:25832',
          });
          coordinates = (punkt.getGeometry() as Point).getCoordinates();
        }

        const nummer = bygning.byg007Bygningsnummer
          ? bygning.byg007Bygningsnummer.toString()
          : '0';

        const anvendelseTekst =
          typeTexts.BYG_ANVEND_KODE[bygning.byg021BygningensAnvendelse] ||
          'Ukendt anvendelse';

        // tslint:disable-next-line:max-line-length
        const bpfg =
          bygning.bygningPåFremmedGrundList &&
          bygning.bygningPåFremmedGrundList.length > 0
            ? bygning.bygningPåFremmedGrundList.filter(
                (b) => b.bygningPåFremmedGrund.status < 9,
              )
            : null;

        const bpfgBfe =
          bpfg && bpfg.length > 0
            ? bpfg[0].bygningPåFremmedGrund.bfeNummer
            : null;

        items.push({
          id: bygning.id_lokalId,
          title: anvendelseTekst,
          subtitle: 'Bygning ' + nummer,
          description: bygning.adgangsadressebetegnelse,
          icon,
          iconColor,
          shortname: nummer,
          x: coordinates ? coordinates[0] : null,
          y: coordinates ? coordinates[1] : null,
          attributes,
          bpfg: !!bpfgBfe,
          ejendomsnummer: bpfgBfe,
          bfeNummer: (bygning as any).bfeNummer,
          accuracy,
          showWarning: accuracy !== 'sikker',
          jordstykke: bygning.jordstykke,
          data: bygning,
          type: ItemType.bygning,
          sagstype: bygning.sagstype,
          isNybyggeri:
            bygning.sagstype === '1' &&
            (bygning.status == '2' ||
              bygning.status == '3' ||
              bygning.status == '12' ||
              bygning.status == '13') &&
            (bygning.sagsstatus == 5 ||
              bygning.sagsstatus == 12 ||
              bygning.sagsstatus == 13),
          isNedrivning:
            bygning.status == '6' &&
            bygning.sagstype == '32' &&
            (bygning.sagsstatus == 5 ||
              bygning.sagsstatus == 12 ||
              bygning.sagsstatus == 13) &&
            (bygning.sagsobjectstatus == '2' ||
              bygning.sagsobjectstatus == '3'),
        } as Item);
      }

      for (const bygning of state.dafResult.tekniskeAnlæg as TekniskeAnlaeg[]) {
        if (items.findIndex((i) => i.id === bygning.id_lokalId) > -1) {
          continue;
        }

        const attributes: Attribute[] = [];

        attributes.push({
          name: 'Etableringsår',
          value: (bygning.tek024Etableringsår || 'Ukendt år').toString(),
        });

        if (bygning.tek037Areal) {
          attributes.push({
            name: 'Areal',
            value: bygning.tek037Areal.toString() + ' m2',
          });
        }

        if (bygning.tek027Placering) {
          attributes.push({
            name: 'Placering',
            value:
              typeTexts.OlieTankPlacer[bygning.tek027Placering] || 'Ukendt',
          });
        }

        if (bygning.tek034IndholdOlietank) {
          attributes.push({
            name: 'Indhold',
            value: typeTexts.Indhold[bygning.tek034IndholdOlietank] || 'Ukendt',
          });
        }

        // if (bygning.tek076KildeTilKoordinatsæt) {
        //   attributes.push({
        //     name: 'Kilde til koordinat',
        //     value: typeTexts.KoordinatKilde[bygning.tek076KildeTilKoordinatsæt] || 'Ukendt',
        //   });
        // }

        if (bygning.tek110Driftstatus) {
          attributes.push({
            name: 'Status',
            value:
              typeTexts.TekniskanlaegDriftsstatus[bygning.tek110Driftstatus] ||
              'Ukendt',
          });
        }

        if (bygning.tek500notatlinjer) {
          attributes.push({
            name: 'Notatlinje',
            value: bygning.tek500notatlinjer,
          });
        }

        let icon: StrukturUnderType = null;
        let iconColor: string = '#008100';
        let accuracy: string = 'sikker';

        switch (bygning.tek077KvalitetAfKoordinatsæt) {
          case '1':
            iconColor = '#008100';
            accuracy = 'sikker';
            break;
          default:
            iconColor = '#db0101';
            accuracy = 'usikker';
            break;
        }

        // tslint:disable-next-line:max-line-length
        icon = getTekniskAnlaeg(
          parseInt(bygning.tek020Klassifikation, 10),
          parseInt(bygning.tek034IndholdOlietank, 10),
        );

        const format = new WKT();

        let coordinates = null;
        if (bygning.tek109Koordinat) {
          const punkt = format.readFeature(bygning.tek109Koordinat, {
            dataProjection: 'EPSG:25832',
          });
          coordinates = (punkt.getGeometry() as Point).getCoordinates();
        }

        const bpfgBfe = bygning.bygningPåFremmedGrund
          ? bygning.bygningPåFremmedGrund.bfeNummer
          : null;

        // tslint:disable-next-line:max-line-length
        const nummer = bygning.tek020Klassifikation
          ? bygning.tek007Anlægsnummer.toString()
          : state.dafResult.tekniskeAnlæg.length === 1
          ? '1'
          : '0';
        const klassifikationTekst =
          typeTexts.Klassifikation[bygning.tek020Klassifikation] || 'Ukendt';
        items.push({
          id: bygning.id_lokalId,
          title: klassifikationTekst,
          subtitle: 'Teknisk Anlæg ' + nummer,
          description: null,
          icon,
          iconColor,
          shortname: 'T' + nummer,
          x: coordinates ? coordinates[0] : null,
          y: coordinates ? coordinates[1] : null,
          attributes,
          bpfg: !!bpfgBfe,
          ejendomsnummer: bpfgBfe,
          bfeNummer: (bygning as any).bfeNummer,
          accuracy,
          showWarning: accuracy !== 'sikker',
          jordstykke: bygning.jordstykke,
          data: bygning,
          type: ItemType.tekniskanlaeg,
          sagstype: bygning.sagstype,
          isNybyggeri:
            bygning.sagstype === '1' &&
            (bygning.status == '2' || bygning.status == '3') &&
            (bygning.sagsstatus == 5 ||
              bygning.sagsstatus == 12 ||
              bygning.sagsstatus == 13),
          isNedrivning:
            bygning.status == '6' &&
            bygning.sagstype == '32' &&
            (bygning.sagsstatus == 5 ||
              bygning.sagsstatus == 12 ||
              bygning.sagsstatus == 13) &&
            (bygning.sagsobjectstatus == '2' ||
              bygning.sagsobjectstatus == '3'),
        } as Item);

        if (bygning.bygning) {
          const tilknyttet = items.find((i) => i.id === bygning.bygning);
          if (tilknyttet) {
            attributes.push({
              name: 'Bygningstilknytning',
              value: tilknyttet.subtitle,
            });

            items[items.length - 1].tilknytninger = [tilknyttet];

            if (!tilknyttet.tilknytninger) {
              tilknyttet.tilknytninger = [];
            }
            tilknyttet.tilknytninger.push(items[items.length - 1]);
          }
        }
      }

      items.sort((a, b) => {
        return a.shortname.localeCompare(b.shortname, undefined, {
          numeric: true,
        });
      });

      return items;
    },
  },
  mutations: {
    mapPosition(state: any, mapPosition: PositionAndZoom): void {
      Object.freeze(mapPosition);
      state.mapPosition = mapPosition;
    },
    propertyHusnummerPosition(state: any, result: string): void {
      Object.freeze(result);
      state.propertyHusnummerPosition = result;
    },
    searchResult(state: any, searchResult: any): void {
      state.searchResult = searchResult;
    },
    searchSuggestion(state: any, searchSuggestion: any): void {
      Object.freeze(searchSuggestion);
      state.searchSuggestion = searchSuggestion;
    },
    errorMessage(state: any, message: any): void {
      state.errorMessage = message;
    },
    tmpErrorMessage(state: any, message: string): void {
      state.tmpErrorMessage = message;
    },
    dafResult(state: any, result: any): void {
      Object.freeze(result);
      state.dafResult = result;
    },
    activeItem(state: any, item: Item): void {
      state.activeItem = item;
    },
    selectedItem(state: any, item: Item): void {
      state.selectedItem = item;
    },
    isLoading(state: any, value: boolean): void {
      state.isLoading = value;
    },
    showLegend(state: any, value: boolean): void {
      state.showLegend = value;
    },
    showSidebar(state: any, value: boolean): void {
      state.showSidebar = value;
    },
    showPrint(state: any, value: boolean): void {
      state.showPrint = value;
    },
    showAbout(state: any, value: boolean): void {
      state.showAbout = value;
    },
    setSelectedId(state: any, id: string): void {
      state.selectedId = id;
    },
    activeMapLayer(state: any, value: string): void {
      state.activeMapLayer = value;
    },
    searchComponentResult(state: any, value: SearchComponentResult): void {
      state.searchComponentResult = value;
    },
    selectedEjendom(state: any, value: EjendomResult): void {
      state.selectedEjendom = value;
    },
  },
  actions: {
    setActiveItem(context: any, payload: SetActiveItemArgs): void {
      if (!payload) {
        return;
      }

      if (!payload.id) {
        context.commit('activeItem', null);
        if (payload.click) {
          context.commit('selectedItem', null);
          context.commit('setSelectedId', null);
        }
        return;
      }

      const item: Item = context.getters.items.find(
        (i: Item) => i.id === payload.id,
      );
      if (payload.click) {
        context.commit('activeItem', null);

        if (item) {
          $$events.$emit('goToItem', item);
          $$events.$emit('zoomToItem', item);
          if (payload.map) {
            context.commit('showSidebar', true);
          } else if (isMobileSetup()) {
            context.commit('showSidebar', false);
          }
        }

        context.commit('selectedItem', item);
        context.commit('setSelectedId', item ? item.id : null);
      } else {
        context.commit('activeItem', item);
      }
    },
    nextItem(context: any, direction: number) {
      if (context.getters.items.length === 0) {
        return;
      }

      let activeItem = context.state.activeItem;
      if (!activeItem) {
        activeItem = context.state.selectedItem;
      }
      let newIndex = 0;
      if (activeItem) {
        const index: number = context.getters.items.findIndex(
          (i: Item) => i.id === activeItem.id,
        );

        newIndex = index + direction;
      }

      if (newIndex < 0) {
        newIndex = context.getters.items.length - 1;
      } else if (newIndex > context.getters.items.length - 1) {
        newIndex = 0;
      }

      context.dispatch('setActiveItem', {
        id: context.getters.items[newIndex].id,
        click: false,
      } as SetActiveItemArgs);
    },
    setError(context: any, error: any): void {
      if (!error) {
        context.commit('errorMessage', null);
        return;
      }
      context.commit('isLoading', false);
      context.commit('searchSuggestion', null);
      context.commit('dafResult', null);
      context.commit('selectedEjendom', null);
      context.commit('errorMessage', error);

      router
        .push({
          name: router.currentRoute.name,
          query: Object.assign({}, router.currentRoute.query, {
            bfe: undefined,
          }),
        })
        .catch((err) => {
          // todo fejlbesked
        });
    },
    clearError(context: any) {
      context.dispatch('setError', null);
    },
    async search(context: any, ejendom: EjendomResult): Promise<void> {
      context.dispatch('clearError', null);
      context.commit('searchSuggestion', null);
      context.commit('dafResult', null);
      context.commit('selectedEjendom', ejendom);
      if (!ejendom) {
        router
          .push({
            name: router.currentRoute.name,
            query: Object.assign({}, router.currentRoute.query, {
              bfe: undefined,
            }),
          })
          .catch((err) => {
            // todo fejlbesked
          });

        return;
      }

      context.commit('isLoading', true);

      try {
        if (context.state.searchComponentResult === ejendom) {
          const arr: DafResult[] = [];
          for (const ej of context.getters.ejendomme) {
            const result = await EjendomSearchApi.getData(ej.data);
            Object.freeze(result);
            arr.push(result);
          }
          const feat = await EjendomSearchApi.getEjendomsbeliggenhed(
            ejendom.data.bfeNummer,
          );
          const f = feat.filter((c) => c.properties.status.startsWith('g'));

          if (Array.isArray(f) && f.length) {
            if (
              Array.isArray(f[0].properties.husnummer) &&
              f[0].properties.husnummer.length
            ) {
              context.commit(
                'propertyHusnummerPosition',
                f[0].properties.husnummer[0].adgangspunkt.position,
              );
            } else if (
              Array.isArray(f[0].properties.adresse) &&
              f[0].properties.adresse.length &&
              f[0].properties.adresse[0].husnummer != null
            ) {
              context.commit(
                'propertyHusnummerPosition',
                f[0].properties.adresse[0].husnummer.adgangspunkt.position,
              );
            } else {
              context.commit('propertyHusnummerPosition', null);
            }
          } else {
            context.commit('propertyHusnummerPosition', null);
          }
          context.commit('dafResult', {
            ejendomme: arr,
            get bygninger() {
              return this.ejendomme.flatMap((b) =>
                b.bygninger.map((x) =>
                  Object.assign(x, { bfeNummer: b.bfeNummer }),
                ),
              );
            },
            get grunddataKeys() {
              return this.ejendomme.flatMap((b) => b.grunddataKeys);
            },
            get tekniskeAnlæg() {
              return this.ejendomme.flatMap((b) =>
                b.tekniskeAnlæg.map((x) =>
                  Object.assign(x, { bfeNummer: b.bfeNummer }),
                ),
              );
            },
            get ejendomsnummer() {
              return [
                ...new Map(
                  this.ejendomme
                    .flatMap((b) => b.ejendomsnummer)
                    .map((item) => [item['ejendomsnummer'], item]),
                ).values(),
              ];
            },
          });
        } else {
          const result = await EjendomSearchApi.getData(ejendom.data);
          Object.freeze(result);

          const feat = await EjendomSearchApi.getEjendomsbeliggenhed(
            ejendom.data.bfeNummer,
          );
          const f = feat.filter((c) => c.properties.status.startsWith('g'));
          if (Array.isArray(f) && f.length) {
            if (
              Array.isArray(f[0].properties.husnummer) &&
              f[0].properties.husnummer.length
            ) {
              context.commit(
                'propertyHusnummerPosition',
                f[0].properties.husnummer[0].adgangspunkt.position,
              );
            } else if (
              Array.isArray(f[0].properties.adresse) &&
              f[0].properties.adresse.length &&
              f[0].properties.adresse[0].husnummer != null
            ) {
              context.commit(
                'propertyHusnummerPosition',
                f[0].properties.adresse[0].husnummer.adgangspunkt.position,
              );
            } else {
              context.commit('propertyHusnummerPosition', null);
            }
          } else {
            context.commit('propertyHusnummerPosition', null);
          }
          context.commit('dafResult', result);
        }
        router
          .push({
            name: router.currentRoute.name,
            query: Object.assign({}, router.currentRoute.query, {
              bfe: ejendom.data.bfeNummer,
            }),
          })
          .catch((err) => {
            // todo fejlbesked
          });

        $$events.$emit('set-search-blur');
        context.commit('isLoading', false);
      } catch (error) {
        context.commit('isLoading', false);
        context.dispatch('setError', 'Det var ikke muligt at hente ejendommen');
      }
    },
  },
  modules: {},
});
