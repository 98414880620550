<template>
  <footer class="l-footer bg-light pt-4 pb-2">
    <div class="row no-gutters">
      <div class="col-auto mr-auto">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click="$store.commit('showAbout', true)"
              >Om BBR Kort</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://bbr.dk/kontakt-din-kommune"
              target="_blank"
              >Kontakt din kommune</a
            >
          </li>
        </ul>
      </div>
      <div class="col-auto">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
              class="nav-link"
              target="_blank"
              href="http://bbr.dk/persondata"
              title="Persondatapolitik (Åbner i nyt vindue)"
              rel="noreferrer"
            >
              <span
                class="nav-link__external"
                :style="{
                  background:
                    'url(' +
                    require('./../assets/ikoner/pointer-external.svg') +
                    ') no-repeat 100% 100%',
                }"
              >
                Privatlivspolitik
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              target="_blank"
              href="http://bbr.dk/cookies"
              title="Cookiepolitik (Åbner i nyt vindue)"
              rel="noreferrer"
            >
              <span
                class="nav-link__external"
                :style="{
                  background:
                    'url(' +
                    require('./../assets/ikoner/pointer-external.svg') +
                    ') no-repeat 100% 100%',
                }"
              >
                Cookiepolitik
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              target="_blank"
              href="https://www.was.digst.dk/kort-bbr-dk"
              title="Tilgængelighedserklæring (Åbner i nyt vindue)"
              rel="noreferrer"
            >
              <span
                class="nav-link__external"
                :style="{
                  background:
                    'url(' +
                    require('./../assets/ikoner/pointer-external.svg') +
                    ') no-repeat 100% 100%',
                }"
              >
                Tilgængelighedserklæring
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Footer extends Vue {
  get baseUrl() {
    return process.env.BASE_URL;
  }
}
</script>
