<template>
  <div id="app">
    <div
      v-if="showError"
      class="c-overall-alert alert alert-danger justify-content-between align-items-center d-flex"
      role="alert"
      style="z-index:9999;"
    >
      <span>
        <strong>Fejl:</strong>
        {{ tmpErrorMessage }}
      </span>
      <button class="btn btn-danger" @click="showError = false">Luk</button>
    </div>
    <a
      class="skip-main"
      href="#main-content"
      role="navigation"
      aria-labelledby="skipcontent"
    >
      <strong>Gå til hovedindhold</strong> (Springer ejendoms søgefeltet over)
    </a>
    <router-view />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dictionary, Route } from 'vue-router/types/router';
import ReverseApi from './helpers/api/ReverseApi';
import { $$events } from './helpers/events';
import { PositionAndZoom } from './models/PositionAndZoom';
import { AxiosError, AxiosResponse } from 'axios';
import { EjendomResult } from './models/AutocompleteResult';
import EjendomSearchApi from './helpers/api/DafApi';

let timeoutHandle = null;

@Component({
  components: {},
})
export default class App extends Vue {
  @Watch('tmpErrorMessage')
  private tmpErrorMessageChanged(value: string) {
    const vm = this;
    clearTimeout(timeoutHandle);
    if (value) {
      timeoutHandle = setTimeout(() => {
        vm.showError = false;
      }, 5000);
    }
  }

  get showError(): boolean {
    return !!this.$store.state.tmpErrorMessage;
  }

  set showError(value: boolean) {
    if (!value) {
      this.$store.commit('tmpErrorMessage', null);
    }
  }

  get tmpErrorMessage(): string {
    return this.$store.state.tmpErrorMessage;
  }

  set tmpErrorMessage(value: string) {
    this.$store.commit('tmpErrorMessage', value);
  }

  get mapPosition(): PositionAndZoom {
    return this.$store.state.mapPosition;
  }

  set mapPosition(value: PositionAndZoom) {
    this.$store.commit('mapPosition', value);
  }

  get mapPositionText(): string {
    if (!this.mapPosition) {
      return null;
    }
    return `${this.mapPosition.x},${this.mapPosition.y},${this.mapPosition.zoom}`;
  }

  @Watch('mapPositionText')
  private mapPositionChanged(mapPositionText: string) {
    if (this.$route.query.p && this.$route.query.p === mapPositionText) {
      return;
    }

    this.$router
      .replace({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, { p: mapPositionText }),
      })
      .catch((err) => {
        // todo fejlbesked
      });
  }

  private readMapPositionFromQuery() {
    const position = this.$route.query.p as string;
    if (position) {
      const positionArray = position.split(',');
      if (positionArray.length === 3) {
        const positionAndZoom = {
          x: parseFloat(positionArray[0]),
          y: parseFloat(positionArray[1]),
          zoom: parseFloat(positionArray[2]),
        } as PositionAndZoom;
        if (
          !isNaN(positionAndZoom.x) &&
          !isNaN(positionAndZoom.y) &&
          !isNaN(positionAndZoom.zoom)
        ) {
          this.mapPosition = positionAndZoom;
        }
      }
    }
  }

  get selectedId(): string {
    return this.$store.getters.selectedId;
  }

  set selectedId(value: string) {
    this.$store.commit('setSelectedId', value);
  }

  @Watch('selectedId')
  private selectedIdChanged(selectedId: string) {
    if (
      this.$route.query.selected &&
      this.$route.query.selected === selectedId
    ) {
      return;
    }

    this.$router
      .push({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, { selected: selectedId }),
      })
      .catch((err) => {
        // todo fejlbesked
      });
  }

  @Watch('$route.path', { immediate: false, deep: true })
  private onRouteNameChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.updateSearchFromRoute();
    }
  }

  private async updateSearchFromRoute() {
    const route = this.$route;
    const vm = this;
    switch (route.name) {
      case 'adgangsadresse': {
        const adgangsadresseId = route.params.id;
        $$events.$emit('set-search-id', {
          sourceName: 'dawa.adresse',
          id: `aa_${adgangsadresseId}`,
        });
        break;
      }
      case 'adresse': {
        const adresseId = route.params.id;
        $$events.$emit('set-search-id', {
          sourceName: 'dawa.adresse',
          id: `a_${adresseId}`,
        });
        break;
      }
      case 'matrikel': {
        const ejerlavsKode = route.params.ejerlavsKode;
        const matrikelNr = route.params.id;
        $$events.$emit('set-search-id', {
          sourceName: 'dawa.jordstykke',
          id: `${ejerlavsKode}_${matrikelNr}`,
        });
        break;
      }
      case 'ejendom': {
        const ejendomsnr = route.params.id;
        $$events.$emit('set-search-id', {
          sourceName: 'daf.ejendom',
          id: ejendomsnr,
        });
        break;
      }
      // case 'esr': {
      //   $$events.$emit('set-search-id', {
      //     sourceName: 'daf.ejendomsnummer',
      //     id: `${route.params.kommunekode} ${route.params.id}`,
      //   });
      //         /*
      //         try {
      //           this.$store.commit('isLoading', true);
      //           const esr = await EjendomSearchApi.mapESRToBFE(
      //             route.params.kommunekode,
      //             route.params.id,
      //           );
      //           if (esr && esr.length > 0) {
      //             $$events.$emit('set-search-id', {
      //               sourceName: 'bfenumbers',
      //               id: 'ESR: ' + route.params.kommunekode + '-' + route.params.id,
      //               searchtitle: 'det kommunale ejendomsnummer ' + route.params.kommunekode + '-' + route.params.id,
      //               bfeNumbers: esr,
      //             });
      //           }
      //         } finally {
      //           this.$store.commit('isLoading', false);
      //         }
      // */
      //    break;
      // }
      // case 'kommunaltejendomsnummer': {
      //   $$events.$emit('set-search-id', {
      //     sourceName: 'daf.ejendomsnummer',
      //     id: route.params.id,
      //   });
      //         /*
      //         try {
      //           this.$store.commit('isLoading', true);
      //           const esr = await EjendomSearchApi.mapESRToBFE(
      //             route.params.kommunekode,
      //             route.params.id,
      //           );
      //           if (esr && esr.length > 0) {
      //             $$events.$emit('set-search-id', {
      //               sourceName: 'bfenumbers',
      //               id: 'ESR: ' + route.params.kommunekode + '-' + route.params.id,
      //               searchtitle: 'det kommunale ejendomsnummer ' + route.params.kommunekode + '-' + route.params.id,
      //               bfeNumbers: esr,
      //             });
      //           }
      //         } finally {
      //           this.$store.commit('isLoading', false);
      //         }
      // */
      //    break;
      //  }
      default:
        if (this.$route.query.bfe) {
          $$events.$emit('set-search-id', {
            sourceName: 'daf.ejendom',
            id: this.$route.query.bfe,
          });
        }
        return;
    }

    this.$router
      .push({
        name: 'home',
        query: this.$route.query,
      })
      .catch((err) => {
        // todo fejlbesked
      });
  }

  private created() {
    this.readMapPositionFromQuery();
    this.selectedId = this.$route.query.selected as string;

    $$events.$on('coordinateClicked', this.coordinateClicked);
  }

  private mounted() {
    this.updateSearchFromRoute();
  }

  private beforeDestroy() {
    $$events.$off('coordinateClicked', this.coordinateClicked);
  }

  private coordinateClicked(coordinate: number[]) {
    if (this.mapPosition && this.mapPosition.zoom < 11) {
      return;
    }

    const vm = this;
    ReverseApi.Get(coordinate)
      .then((result) => {
        const ejendom = vm.$store.state.selectedEjendom as EjendomResult;
        if (
          result &&
          (!ejendom || ejendom.data.bfeNummer !== result.bfenummer.toString())
        ) {
          vm.$store.commit('searchSuggestion', result);
        } else {
          vm.$store.commit('searchSuggestion', null);
        }
      })
      .catch((e) => {
        vm.$store.commit(
          'tmpErrorMessage',
          'Det var ikke muligt at hente ejendommen',
        );
      });
  }
}
</script>
