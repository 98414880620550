import { StrukturUnderType } from './StrukturUnderType';

export class StrukturHovedType {
  public navn: string;
  public label: string;
  public kortLabel: string;
  public ikon: string;
  public anvendelser: StrukturUnderType[];

  constructor({
    navn,
    label,
    kortLabel,
    ikon,
    anvendelser,
  }: {
    navn: string;
    label: string;
    kortLabel: string;
    ikon: string;
    anvendelser: StrukturUnderType[];
  }) {
    this.navn = navn;
    this.label = label;
    this.kortLabel = kortLabel;
    this.ikon = ikon;
    this.anvendelser = anvendelser;
  }
}
