<template>
  <div
    class="modal"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="'modalLabel' + id"
    aria-hidden="true"
    @click.self="close()"
    ref="modal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'modalLabel' + id">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
          >
            <!-- <span aria-hidden="true">&times;</span> -->
            <img
              src="./../assets/ikoner/icon-close.svg"
              alt="Luk ikon"
              style="height: 16px"
            />
          </button>
        </div>
        <div class="modal-body">
          <slot>Default</slot>
        </div>
        <div class="modal-footer align-self-start">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            @click="close()"
          >
            luk
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
declare const $: any;
@Component({
  components: {},
})
export default class Modal extends Vue {
  private static id: number = 0;
  @Prop({ default: 'Test' })
  private title: string;
  @Prop({ default: false })
  private value: boolean;

  @Watch('value')
  private valueChanged(value: boolean) {
    if (value) {
      $(this.$refs.modal).modal('show');
    } else {
      $(this.$refs.modal).modal('hide');
    }
  }

  private destroy() {
    $(this.$refs.modal).modal('dispose');
  }

  private created() {
    Modal.id++;
  }

  get id() {
    return Modal.id;
  }

  private close() {
    this.$emit('input', false);
  }
}
</script>
