<template>
  <div>
    <!-- <Modal v-model="showError" :title="'Test1'">
      Der skete en fejl
    </Modal> -->

    <Modal v-model="showAbout" title="Om BBR Kort">
      <p style="margin-top: -12px; margin-bottom: 20px;">
        <strong>Version:</strong> {{ version }}
      </p>
      <p>
        Kortet herunder er alene tænkt som en service, der kan give et overblik
        over ejendommen.
      </p>
      <p>
        Byggeaktivitet m.v. siden seneste luftfoto kan have ændret forholdene.
        Vær også opmærksom på, at der af tekniske grunde kan forekomme
        uoverensstemmelser, når matrikelkortet vises sammen med f.eks. luftfotos
        eller omrids af bygninger. Kortvisningen nedenfor kan derfor ikke stå
        alene som f.eks. grundlag for en byggesag eller til at afgøre et skels
        nøjagtige beliggenhed.
      </p>
      <p>
        Hvis der er tvivl om skellets beliggenhed, og dette ønskes fastlagt,
        henvises til en praktiserende landinspektør.
      </p>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from './Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class Modals extends Vue {
  get showAbout(): boolean {
    return this.$store.state.showAbout;
  }

  set showAbout(value: boolean) {
    this.$store.commit('showAbout', value);
  }

  get version(): string {
    return process.env.VUE_APP_VERSION;
  }
}
</script>
