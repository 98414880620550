import axios from 'axios';
import EjendomSearchApi from './DafApi';

abstract class ReverseApi {
  public static async Get(coordinate: number[]): Promise<any> {
    const result = await axios.get(
      `https://dawa.aws.dk/jordstykker?x=${coordinate[0]}&y=${coordinate[1]}&srid=25832`,
    );

    if (result.data.length === 0) {
      return null;
    }

    const jordstykkeResult = await axios.get(
      `${result.data[0].href}?format=geojson&srid=25832`,
    );

    result.data[0].geometri = jordstykkeResult.data.geometry;
    result.data[0].bfenummer = result.data[0].sfeejendomsnr;
    const ejendomResult = await EjendomSearchApi.getEjendomsbeliggenhed(
      result.data[0].bfenummer,
    );
    if (ejendomResult.length === 0) {
      return null;
    }

    const beliggenhed = ejendomResult[0];
    if (beliggenhed.properties.husnummer.length > 0) {
      result.data[0].adresseBetegnelse =
        beliggenhed.properties.husnummer[0].adgangsadressebetegnelse;
      result.data[0].vejnavn =
        beliggenhed.properties.husnummer[0].navngivenVej.vejnavn;
      result.data[0].husnummertekst =
        beliggenhed.properties.husnummer[0].husnummertekst;
      result.data[0].postnr =
        beliggenhed.properties.husnummer[0].postnummer.postnr;
      result.data[0].postdistrikt =
        beliggenhed.properties.husnummer[0].postnummer.navn;
      result.data[0].kommunenavn =
        beliggenhed.properties.husnummer[0].kommuneinddeling.navn;
    } else if (beliggenhed.properties.adresse.length > 0) {
      result.data[0].adresseBetegnelse =
        beliggenhed.properties.adresse[0].adressebetegnelse;
      result.data[0].vejnavn =
        beliggenhed.properties.adresse[0].husnummer.navngivenVej.vejnavn;
      result.data[0].husnummertekst =
        beliggenhed.properties.adresse[0].husnummer.husnummertekst;
      result.data[0].etagebetegnelse =
        beliggenhed.properties.adresse[0].etagebetegnelse;
      result.data[0].dørbetegnelse =
        beliggenhed.properties.adresse[0].dørbetegnelse;
      result.data[0].postnr =
        beliggenhed.properties.adresse[0].husnummer[0].postnummer.postnr;
      result.data[0].postdistrikt =
        beliggenhed.properties.adresse[0].husnummer[0].postnummer.navn;
      result.data[0].kommunenavn =
        beliggenhed.properties.adresse[0].husnummer[0].kommuneinddeling.navn;
    } else {
      result.data[0].adresseBetegnelse = beliggenhed.properties.betegnelse;
      result.data[0].vejnavn = beliggenhed.properties.betegnelse;
      result.data[0].husnummertekst = '';
      result.data[0].postnr = ''; // 'Ingen adresse';
      result.data[0].postdistrikt = '';
      result.data[0].kommunenavn = result.data[0].kommune.navn;
    }

    return result.data[0];
  }
}

export default ReverseApi;
