<template>
  <nav
    class="navbar navbar-light no-gutters"
    style="height: 64px; position: relative;"
    role="banner"
    aria-labelledby="navtop"
  >
    <a
      class="mr-auto navbar-brand navbar-brand--bbr"
      title="Kort BBR (Startside)"
      :href="baseUrl"
    >
      <img
        src="./../assets/images/BBR-Kort-logo-2.svg"
        height="100%"
        width="100px"
        class="d-inline-block align-top"
        alt="Logo BBR Kort"
      />
    </a>
    <a
      class="navbar-brand navbar-brand--ufst mr-0 pt-0"
      title="Vurderingsstyrelsen (Åbner i nyt vindue)"
      href="https://vurdst.dk"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="./../assets/images/vurdering-logo.svg"
        height="32"
        width="auto"
        class="d-inline-block align-top"
        alt="Vurderingsstyrelsen"
      />
    </a>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SearchComponent from './SearchComponent.vue';

@Component({
  components: {
    SearchComponent,
  },
})
export default class Header extends Vue {
  get baseUrl() {
    return process.env.BASE_URL;
  }
}
</script>
