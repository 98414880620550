<template>
  <div>
    <div class="px-3 pb-3" style="box-sizing: border-box;">
      <h1 class="text-info mb-2" style="padding-left: 3px;">
        <strong>Find ejendomme og <br />se dem på kortet</strong>
      </h1>
      <h2 class="mt-5 mb-3 h6" style="padding-left: 3px;">
        <strong>Søg efter</strong>
      </h2>
      <div style="padding-left: 3px;">
        <div class="list-group list-group--search">
          <button
            type="button"
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-info"
            @click="selectSource('dawa.adresse')"
          >
            <div class="d-flex align-items-center">
              <img
                src="./../assets/ikoner/search/icon-location.svg"
                alt="Søgningstype"
                class="mr-3"
              />
              <h6 class="mb-0 d-inline"><strong>Adresse</strong></h6>
            </div>
            <img
              src="./../assets/ikoner/pointer.svg"
              alt="Pil højre ikon"
              class="float-right"
            />
          </button>
          <button
            type="button"
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-info"
            @click="selectSource('daf.ejendom')"
          >
            <div class="d-flex align-items-center">
              <img
                src="./../assets/ikoner/search/icon-ejendom.svg"
                alt="Søgningstype"
                class="mr-3"
              />
              <h6 class="mb-0 d-inline"><strong>BFE-nr.</strong></h6>
            </div>
            <img
              src="./../assets/ikoner/pointer.svg"
              alt="Pil højre ikon"
              class="float-right"
            />
          </button>
          <button
            type="button"
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-info"
            @click="selectSource('dawa.jordstykke')"
          >
            <div class="d-flex align-items-center">
              <img
                src="./../assets/ikoner/search/icon-matrikel.svg"
                alt="Søgningstype"
                class="mr-3"
              />
              <h6 class="mb-0 d-inline">
                <strong>Matrikelbetegnelse</strong>
              </h6>
            </div>
            <img
              src="./../assets/ikoner/pointer.svg"
              alt="Pil højre ikon"
              class="float-right"
            />
          </button>
          <!-- <button
            type="button"
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-info"
            @click="selectSource('daf.ejendomsnummer')"
          >
            <div class="d-flex align-items-center">
              <img
                src="./../assets/ikoner/search/icon-ejendom.svg"
                alt="Søgningstype"
                class="mr-3"
              />
              <h6 class="mb-0 d-inline"><strong>Kommunalt ejendoms nr.</strong></h6>
            </div>
            <img
              src="./../assets/ikoner/pointer.svg"
              alt="Pil højre ikon"
              class="float-right"
            />
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { EjendomResult } from '@/models/AutocompleteResult';
import { $$events } from '@/helpers/events';

@Component({
  components: {},
  computed: { ...mapGetters(['ejendomme']) },
})
export default class WelcomeComponent extends Vue {
  private selectSource(sourceName: string) {
    $$events.$emit('setActiveSource', sourceName);
  }
}
</script>
