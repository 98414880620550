import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

// tslint:disable-next-line:typedef
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/link/adresse/:id',
    name: 'adresse',
    component: Home,
  },
  {
    path: '/link/adgangsadresse/:id',
    name: 'adgangsadresse',
    component: Home,
  },
  {
    // /link/matrikel/2000652/85b
    path: '/link/matrikel/:ejerlavsKode/:id',
    name: 'matrikel',
    component: Home,
  },
  {
    path: '/link/ejendom/:id',
    name: 'ejendom',
    component: Home,
  },
  {
    path: '/link/kommunaltejendomsnummer/:id',
    name: 'kommunaltejendomsnummer',
    component: Home,
  },
  {
    path: '/link/esr/:kommunekode/:id',
    name: 'esr',
    component: Home,
  },
  {
    // will match everything
    path: '*',
    redirect: { name: 'home' },
  },
];

// tslint:disable-next-line:typedef
const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

export default router;
