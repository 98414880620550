import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';

export function maskStyle(feature, resolution) {
  const jordstykkeStyle = new Style({
    stroke: new Stroke({
      color: 'rgb(212, 235, 58)',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(212, 235, 58, 0.25)',
    }),
  });

  return jordstykkeStyle;
}
