<template>
  <div class="c-map">
    <div :id="mapTarget" class="c-map-map"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { $$events } from './../helpers/events';
import { Item, ItemType } from '../models/Item';
import { MapWrapper } from './../helpers/map/MapWrapper';
import { PositionAndZoom } from '@/models/PositionAndZoom';

@Component({
  components: {},
})
export default class MapComponent extends Vue {
  @Prop({ default: 'bbr-map' })
  private mapTarget: string;

  @Prop({ default: true })
  private interactive: boolean;

  @Prop({ default: null })
  private startPosition: PositionAndZoom;

  private mapWrapper: MapWrapper = new MapWrapper();

  private mounted() {
    this.mapWrapper.create(
      this.mapTarget,
      this.interactive,
      this.startPosition,
    );

    if (this.interactive) {
      $$events.$on('fitMap', this.fitMap);
      $$events.$on('zoomToItem', this.zoomToItem);
      $$events.$on('map-zoom-in', this.zoomIn);
      $$events.$on('map-zoom-out', this.zoomOut);
    }

    if (!this.startPosition) {
      this.fitMap();
    }
  }

  private beforeDestroy() {
    if (this.interactive) {
      $$events.$off('zoomToItem', this.zoomToItem);
      $$events.$off('fitMap', this.fitMap);
      $$events.$off('map-zoom-in', this.zoomIn);
      $$events.$off('map-zoom-out', this.zoomOut);
    }
    this.mapWrapper.destroy();
  }

  private zoomToItem(item: any) {
    this.mapWrapper.zoomToItem(item);
  }

  private fitMap() {
    this.mapWrapper.fitToMask();
  }

  get items(): Item[] {
    return this.$store.getters.items;
  }

  @Watch('items', { immediate: true })
  private onItemsChanged(items: Item[]) {
    const vm = this;
    this.$nextTick(() => {
      const markers = [];
      if (items && items.length > 0) {
        for (const item of items) {
          if (!item.x || !item.y) {
            continue;
          }

          const marker = {
            id: item.id,
            title: item.icon.label,
            icon: item.icon.ikonFont,
            color: item.iconColor,
            shortname: item.shortname,
            x: item.x,
            y: item.y,
            warning: item.showWarning,
            label: item.isNybyggeri
              ? 'NYBYGGERI'
              : item.isNedrivning
              ? 'NEDRIVNING'
              : null,
            selected: false,
            type: item.type
              ? item.type === ItemType.bygning
                ? 'bygning'
                : 'tekniskanlaeg'
              : null,
          };

          if (
            this.interactive &&
            ((vm.selectedItem && item.id === vm.selectedItem.id) ||
              (vm.selectedId && item.id === vm.selectedId))
          ) {
            marker.selected = true;
          }

          markers.push(marker);
        }
      }
      this.mapWrapper.setMarkers(markers);
    });
  }

  get selectedId(): string {
    return this.$store.state.selectedId;
  }

  get selectedItem(): Item {
    return this.$store.state.selectedItem;
  }

  private zoomIn() {
    this.mapWrapper.zoomIn();
  }

  private zoomOut() {
    this.mapWrapper.zoomOut();
  }
}
</script>
