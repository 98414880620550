<template>
  <main class="l-main">
    <div
      class="row no-gutters"
      style="position: relative; z-index: 99; height: 100%; width: 100%;"
    >
      <Sidebar />
      <div class="c-map-ui text-left" :class="{ 'has-sidebar': showSidebar }">
        <transition name="fade" mode="out-in">
          <div
            class="c-map-card"
            v-if="searchSuggestion"
            @click="performSearchSuggestion"
            :key="searchSuggestion.id"
          >
            <div class="row no-gutters">
              <div class="col-auto c-map-card-header pt-2 pb-2 pl-2">
                <span
                  class="align-items-center d-flex bg-info"
                  style="width: 120px; height: 100%; display: block;"
                >
                  <img
                    src="./../assets/ikoner/icon-location.svg"
                    style="height: 30px; width: 30px; margin: 0 auto;"
                    alt="Billede af grund og bygninger"
                  />
                </span>
              </div>
              <div class="col p-2 pl-3 c-map-card-body">
                <h5 class="card-title mb-0">
                  {{ searchSuggestion.vejnavn }}
                  {{ searchSuggestion.husnummertekst }}
                </h5>
                <div class="c-metadata mb-3">
                  <p class="mt-0 mb-1">
                    {{ searchSuggestion.postnr }}
                    {{ searchSuggestion.postdistrikt }}
                  </p>
                  <p>
                    <strong>Kommune:</strong> {{ searchSuggestion.kommunenavn }}
                  </p>
                  <p>
                    <strong>BFE-nr.:</strong>
                    {{ searchSuggestion.bfenummer }}
                  </p>
                </div>
                <button
                  type="button"
                  class="btn btn-outline-success mr-2 mb-1"
                  @click.stop="performSearchSuggestion"
                >
                  Vis ejendom
                </button>
              </div>
              <div class="col-auto c-map-card-action mr-2 mt-2">
                <button
                  type="button"
                  class="btn btn-img"
                  @click.stop="searchSuggestion = null"
                >
                  <img
                    src="./../assets/ikoner/icon-close.svg"
                    alt=""
                    style="height: 15px;"
                  />
                </button>
              </div>
            </div>
          </div>
        </transition>
        <div class="c-map-ui--bottom-right">
          <button
            type="button"
            @click="fitMap()"
            class="btn btn--map btn-light bg-white d-flex mb-2 l-ui-shadow align-items-center"
            aria-label="Vis alle punkter (Ctrl + m)"
            title="Vis alle punkter (Ctrl + m)"
            v-if="hasResult"
          >
            <img
              src="./../assets/ikoner/map/icon-expand-thick.svg"
              alt=""
              style="height: 16px;"
            />
          </button>
          <div class="btn-group-vertical l-ui-shadow">
            <button
              type="button"
              @click="zoomIn()"
              class="btn btn--map btn-light bg-white"
              aria-label="Zoom ind (+)"
              title="Zoom ind (+)"
            >
              <img
                src="./../assets/ikoner/map/icon-zoom-in-thick.svg"
                alt=""
                style="height: 16px"
              />
            </button>
            <button
              type="button"
              @click="zoomOut()"
              class="btn btn--map btn-light bg-white"
              aria-label="Zoom ud (-)"
              title="Zoom ud (-)"
            >
              <img
                src="./../assets/ikoner/map/icon-zoom-out-thick.svg"
                alt=""
                style="height: 16px"
              />
            </button>
          </div>
        </div>
      </div>
      <Map />
    </div>
    <Modals />
    <PdfPrintComponent v-if="hasResult && showPrint" />
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Map from '@/components/Map.vue';
import Sidebar from '@/components/Sidebar.vue';
import { mapGetters, mapState } from 'vuex';
import { $$events } from '../helpers/events';
import Modals from '../components/Modals.vue';
import PdfPrintComponent from '@/components/PdfPrint.vue';

@Component({
  components: {
    Sidebar,
    Map,
    Modals,
    PdfPrintComponent,
  },
  computed: {
    ...mapState(['showPrint', 'activeMapLayer']),
    ...mapGetters(['hasResult']),
  },
})
export default class Home extends Vue {
  private performSearchSuggestion() {
    $$events.$emit('set-search-id', {
      sourceName: 'daf.ejendom',
      id: this.searchSuggestion.sfeejendomsnr,
    });
    this.searchSuggestion = null;
  }

  get searchSuggestion() {
    return this.$store.state.searchSuggestion;
  }

  set searchSuggestion(value: any) {
    this.$store.commit('searchSuggestion', value);
  }

  private dismissBuildingWarning() {
    this.$store.commit('dismissedBuildingWarning', true);
  }

  private dismissBpfgWarning() {
    this.$store.commit('dismissedBpfgWarning', true);
  }

  private fitMap() {
    $$events.$emit('fitMap');
  }

  private zoomIn() {
    $$events.$emit('map-zoom-in');
  }

  private zoomOut() {
    $$events.$emit('map-zoom-out');
  }

  private changeBackgroundLayer() {
    $$events.$emit('map-change-background-layer');
  }

  get showSidebar(): boolean {
    return this.$store.state.showSidebar;
  }

  set showSidebar(value: boolean) {
    this.$store.commit('showSidebar', value);
  }

  private keyPress(event: KeyboardEvent) {
    if (
      ['input', 'textarea'].indexOf(
        (event.target as HTMLElement).tagName.toLowerCase(),
      ) > -1
    ) {
      return;
    }

    event = event || (window.event as any);
    const charCode = event.which || event.keyCode;
    const charStr = String.fromCharCode(charCode);
    if (event.ctrlKey) {
      switch (charCode) {
        case 2: // b
          this.showSidebar = !this.showSidebar;
          event.preventDefault();
          break;
        case 10: // m
          $$events.$emit('fitMap');
          event.preventDefault();
          break;
        case 9: // i
          this.$store.commit('showLegend', !this.$store.state.showLegend);
          event.preventDefault();
          break;
        case 25: // y
          this.changeBackgroundLayer();
          event.preventDefault();
          break;
      }
    } else if (charStr === '+') {
      $$events.$emit('map-zoom-in');
      event.preventDefault();
    } else if (charStr === '-') {
      $$events.$emit('map-zoom-out');
      event.preventDefault();
    } else if (/[a-z0-9]/i.test(charStr)) {
      // $$events.$emit('set-search-focus');
    }
  }

  private mounted() {
    document.addEventListener('keypress', this.keyPress, false);
  }

  private destroy() {
    document.removeEventListener('keypress', this.keyPress);
  }
}
</script>
