var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"l-footer bg-light pt-4 pb-2"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-auto mr-auto"},[_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.$store.commit('showAbout', true)}}},[_vm._v("Om BBR Kort")])]),_vm._m(0)])]),_c('div',{staticClass:"col-auto"},[_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":"http://bbr.dk/persondata","title":"Persondatapolitik (Åbner i nyt vindue)","rel":"noreferrer"}},[_c('span',{staticClass:"nav-link__external",style:({
                background:
                  'url(' +
                  require('./../assets/ikoner/pointer-external.svg') +
                  ') no-repeat 100% 100%',
              })},[_vm._v(" Privatlivspolitik ")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":"http://bbr.dk/cookies","title":"Cookiepolitik (Åbner i nyt vindue)","rel":"noreferrer"}},[_c('span',{staticClass:"nav-link__external",style:({
                background:
                  'url(' +
                  require('./../assets/ikoner/pointer-external.svg') +
                  ') no-repeat 100% 100%',
              })},[_vm._v(" Cookiepolitik ")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":"https://www.was.digst.dk/kort-bbr-dk","title":"Tilgængelighedserklæring (Åbner i nyt vindue)","rel":"noreferrer"}},[_c('span',{staticClass:"nav-link__external",style:({
                background:
                  'url(' +
                  require('./../assets/ikoner/pointer-external.svg') +
                  ') no-repeat 100% 100%',
              })},[_vm._v(" Tilgængelighedserklæring ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://bbr.dk/kontakt-din-kommune","target":"_blank"}},[_vm._v("Kontakt din kommune")])])
}]

export { render, staticRenderFns }