<template>
  <div v-if="ejendomme">
    <div class="mx-3 mb-3">
      <h1 class="text-info mb-3"><strong>Resultater</strong></h1>
      <p>
        Du har søgt på
        <i style="font-weight: 600;">{{
          searchComponentResult.searchtitle || searchComponentResult.title
        }}</i
        >, der består af {{ ejendomme.length }} BFE-numre.
        <!-- <strong>{{ ejendomme.length }} ejendomme</strong> -->
      </p>

      <!-- <button
        type="button"
        class="c-go-next btn btn-link mb-2"
        @click="select(searchComponentResult)"
        v-if="searchComponentResult.source == 'daf.ejendomsnummer'">
        Vis hele ejendommen
        <img
          src="./../assets/ikoner/pointer.svg"
          alt="Pil højre ikon"
        />
      </button> -->
    </div>
    <div class="c-list list-group c-list--resultlist">
      <button
        type="button"
        class="list-group-item list-group-item-action list-group-item--arrow pa-3 pr-5"
        v-for="ejendom in ejendomme"
        :key="ejendom.id"
        @click="select(ejendom)"
      >
        <h5 class="font-weight-bold pr-2">
          {{ ejendom.title }}
        </h5>
        <div class="c-metadata">
          <p class="text-important-list mb-1">
            <span
              class="d-inline-block text-important-list-text"
              v-if="ejendom.data.isModerEjendom"
              >Moderejendom</span
            >
            <span
              class="d-inline-block text-important-list-text"
              v-if="
                ejendom.data.type && ejendom.data.type !== 'SamletFastEjendom'
              "
              >{{ ejendom.data.typeTekst }}</span
            >
          </p>
          <p>
            <span class="d-inline" v-if="ejendom.data.bfeNummer">
              BFE-nr.: {{ ejendom.data.bfeNummer }}
            </span>
          </p>
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { EjendomResult } from '@/models/AutocompleteResult';

@Component({
  components: {},
  computed: {
    ...mapState(['searchComponentResult']),
    ...mapGetters(['ejendomme']),
  },
})
export default class EjendomSelectorComponent extends Vue {
  private select(ejendom: EjendomResult) {
    this.$store.dispatch('search', ejendom);
  }
}
</script>
